import React, { useState, useEffect, useContext } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { AppProvider } from './components/context/AppContext';
import { redirectTo } from './services/redirect';
import Login from './pages/login';
import Resetpassword from './pages/resetpassword';
import Project from './pages/project';
import Profile from './pages/profile';
import Timesheet from './pages/timesheet';
import TimesheetDetail from './pages/timesheetDetail';
import { subDomain } from './services/redirect';
import Loginredirect from './pages/login.js';
import Holiday from './pages/holiday';
import { Layout, message } from 'antd';
import Navbar from './components/navbar';
import NewHeader from './components/newHeader';
import Report from './pages/report';
import Settings from './pages/admin/settings';
import SalaryUploadPage from './pages/admin/salaryUpload';
import SalarySettingPage from './pages/admin/salarySetting';
import HelpDesk from './pages/helpdesk.js';
import UserRole from './pages/admin/userRole.js';
import UserRoleForm from './pages/admin/userRoleForm.js';
import './components/index.css';
import {
  FeatureFlags,
  FeatureFlagsProvider,
} from './components/context/FeatureFlagContext.js';
import Announce from './pages/announce.js';
import AnnounceDetail from './pages/announceDetail.js';
import ReferenceLetter from './pages/referenceLetter.js';
import MedicalClaim from './pages/medicalClaim.js';
import MedicalClaimRequest from './pages/employee/medicalClaimRequest';
import SalaryDownloadPage from './pages/employee/salary';
import Leave from './pages/leave.js';
import AllUser from './pages/admin/user';
import ProjectDetail from './pages/admin/projectDetail.js';
import defaultProfileImage from './assets/icons/profileimage.svg';
import Claim from './pages/claim';
import BenefitClaimRequest from './pages/employee/benefitClaimRequest.jsx';
import BenefitClaimRequestHr from './pages/hr/BenefitClaimRequest.jsx';
import { ROLE_ID } from './utils/constants.js';
import chatBotIcon from './assets/icons/chatBubble.svg';
import chatBotPic from './assets/icons/chatBotPic.svg';
import PaySlip from './pages/employee/paySlip.jsx';
import PaySlipAdmin from './pages/admin/paySlipAdmin.js';
import MedicalClaimUpload from './pages/hr/medicalClaimUpload.jsx';
import User from './pages/employee/user.js';
import PromotionUploadPage from './pages/hr/promotionUpload.js';

require('dotenv').config();

// import Customer from './pages/admin/customer';
// import AddCustomer from './pages/admin/addcustomer';
// import ViewCustomer from './pages/admin/viewcustomer';
// import Quotation from './pages/admin/quotation';
// import CreateQuotation from './pages/admin/createquotation';
// import User from './pages/admin/user';
// import AllItem from './pages/admin/allitem';
// import AddItem from './pages/admin/additem';
// import ItemInfo from './pages/admin/iteminfo';
// import ProjectForm from './pages/admin/projectform';

const { Content } = Layout;

const chatFlowId = process.env.REACT_APP_FLOWISE_AI_CHATFLOW_ID;
const apiHost = process.env.REACT_APP_FLOWISE_AI_API_HOST;

const signedIn =
  sessionStorage.getItem('access-token') &&
  sessionStorage.getItem('refresh-token');

const PrivateRoute = ({ isSignedIn, ...props }) => {
  return isSignedIn ? <Route {...props} /> : redirectTo(`/login`);
};
const MainRoute = ({ isSignedIn }) => {
  return isSignedIn ? redirectTo(`/helpdesk`) : redirectTo(`/login`);
};
const ProtectedRoute = ({ isAllowed, redirectPath, children }) => {
  if (isAllowed) {
    return children;
  } else {
    message.error(
      "You don't have permission to view this page. Please contact your admin.",
    );
    redirectTo(redirectPath);
  }
};
var jwt = require('jsonwebtoken');
var token = sessionStorage.getItem('access-token');
var decode1 = jwt.decode(token);
const userId = decode1?.userId;

const urlList = [
  { path: '/timesheet', selectedNavbar: 'Timesheet', labelHeader: 'Timesheet' },
  { path: '/project', selectedNavbar: 'Project', labelHeader: 'Project' },
  { path: '/report', selectedNavbar: 'Report', labelHeader: 'Report' },
  { path: '/user/view', selectedNavbar: 'User', labelHeader: 'User Profile' },
  { path: '/user', selectedNavbar: 'User', labelHeader: 'User' },
  { path: '/leave', selectedNavbar: 'Leave', labelHeader: 'Leave Request' },
  { path: '/holiday', selectedNavbar: 'Holidays', labelHeader: 'Holidays' },
  { path: '/settings', selectedNavbar: 'Settings', labelHeader: 'Settings' },
  {
    path: '/reference-letter',
    selectedNavbar: 'Reference Letter',
    labelHeader: 'Reference Letter',
  },
  { path: '/customer', selectedNavbar: 'Customer', labelHeader: 'Customer' },
  { path: '/quotation', selectedNavbar: 'Quotation', labelHeader: 'Quotation' },
  {
    path: '/announce',
    selectedNavbar: 'Announce',
    labelHeader: 'Announce',
  },
  {
    path: '/questionare',
    selectedNavbar: 'Questionare',
    labelHeader: 'Questionare',
  },
  {
    path: '/claim',
    selectedNavbar: 'Claim',
    labelHeader: 'Claim',
  },
  { path: '/profile', selectedNavbar: null, labelHeader: 'My Profile' },
  { path: '/payslip', selectedNavbar: 'Pay Slip', labelHeader: 'Pay Slip' },
  { path: '/helpdesk', selectedNavbar: 'Help Desk', labelHeader: 'Help Desk' },
  {
    path: '/timesheet_new',
    selectedNavbar: 'Timesheet',
    labelHeader: 'Timesheet',
  },
  {
    path: '/role',
    selectedNavbar: 'Role & Permission',
    labelHeader: 'Role & Permission',
  },
  {
    path: '/role/roleForm',
    selectedNavbar: 'Role & Permission',
    labelHeader: 'Role & Permission Detail',
  },
];

function App() {
  const [collapsed, setCollapsed] = useState(false);
  const [urlStartsWith, setUrlStartsWith] = useState({
    path: '/',
    selectedNavbar: null,
    labelHeader: null,
  });
  const location = useLocation();

  //keep track of user role
  const [userState, setUserState] = useState({
    role: decode1?.role,
    roleId: decode1?.roleId,
    currentRoleId: decode1?.currentRoleId,
    currentRoleName: decode1?.currentRole,
    position: decode1?.position,
    fullName: '',
    profileImage: defaultProfileImage,
  });

  useEffect(() => {
    sessionStorage.setItem('current-role-id', userState.currentRoleId);
    sessionStorage.setItem('current-role-name', userState.currentRoleName);
  }, [userState]);

  useEffect(() => {
    console.log(
      `urlStartsWith: ${urlStartsWith.path} ${urlStartsWith.selectedNavbar}`,
    );
  }, [urlStartsWith]);

  useEffect(() => {
    sessionStorage.setItem('this-page', window.location.pathname);
    // Check token
    const urlFound = urlList.find((url) =>
      location.pathname.startsWith(url.path),
    );
    if (urlFound) {
      setUrlStartsWith(urlFound);
    } else if (!urlFound && signedIn) {
      redirectTo(`/helpdesk`);
    } else if (window.location.pathname === `${subDomain}/login`) {
      setUrlStartsWith({
        path: '/login',
        selectedNavbar: null,
        labelHeader: null,
      });
    } else if (
      !(
        window.location.pathname === `${subDomain}/login` ||
        window.location.pathname.search('resetpassword') != -1 ||
        window.location.pathname.search('loginredirect') != -1
      ) &&
      !signedIn
    ) {
      redirectTo(`/`);
    }
    console.log(urlStartsWith);
  }, [location]);

  useEffect(() => {
    if (location.pathname === '/helpdesk') {
      const script = document.createElement('script');
      script.type = 'module';
      script.innerHTML = `
        import('https://cdn.jsdelivr.net/npm/flowise-embed/dist/web.js').then(
          ({ default: Chatbot }) => {
            Chatbot.init({ 
              chatflowid: '${chatFlowId}',
              apiHost: '${apiHost}',
              theme: {
                button: {
                  "backgroundColor": "#1B1D36",
                  "right": 20,
                  "bottom": 20,
                  "size": "40",
                  "customIconSrc": "${chatBotIcon}",
                },
                chatWindow: {
                  "welcomeMessage": "สวัสดี! คุณสามารถสอบถามเกี่ยวกับบริษัท Playtorium Solutions  โดยการพิมพ์ข้อความในช่องข้อความด้านล่างได้เลย!",
                  "backgroundColor": "#ffffff",
                  "fontSize": 14,
                  "poweredByTextColor": "#303235",
                  "clearChatOnReload": true,
                  "botMessage": {
                    "backgroundColor": "#f7f7f7",
                    "textColor": "#000000",
                    "showAvatar": true,
                    "avatarSrc": "${chatBotPic}",
                  },
                  userMessage: {
                    "backgroundColor": "#edf5fe",
                    "textColor": "#000000",
                    "showAvatar": true,
                    "avatarSrc": "${
                      userState.profileImage ?? defaultProfileImage
                    }"
                  },
                  textInput: {
                    "placeholder": "Enter Message",
                    "backgroundColor": "#ffffff",
                    "textColor": "#000000",
                    "sendButtonColor": "#1b1d36"
                  },
                  footer: {
                    "text": "",
                    "company": ""
                  }
                }
              },
            });
          }
        );
      `;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);

        const chatbotElement = document.querySelector('flowise-chatbot');
        if (chatbotElement) {
          chatbotElement.remove();
        }
      };
    }
  }, [location.pathname]);

  // console.log(sessionStorage.getItem("access-token"));
  // console.log("pathname", window.location.pathname);
  const headerHeight = '4rem';
  if (
    (window.location.pathname === `${subDomain}/login` ||
      window.location.pathname.search('resetpassword') != -1 ||
      window.location.pathname.search('loginredirect') != -1) &&
    !signedIn
  ) {
    return (
      <div className="App">
        <Switch>
          <Route exact path={`/login`} component={Login} />
          <Route exact path={`/loginredirect`} component={Loginredirect} />
          <Route
            exact
            path={
              process.env.PUBLIC_URL +
              `/loginredirect/:accessToken/:refreshToken`
            }
            component={Loginredirect}
          />
          <Route
            exact
            path={`/resetpassword/:token`}
            component={Resetpassword}
          />
        </Switch>
      </div>
    );
  } else {
    console.log(urlStartsWith);
    // console.log('public_url', process.env.PUBLIC_URL);

    return (
      <>
        <div className="App">
          <AppProvider value={{ userState, setUserState }}>
            <FeatureFlagsProvider>
              <Layout>
                <Navbar
                  selectedNavbar={urlStartsWith.selectedNavbar}
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                />
                <Layout
                  style={{
                    marginLeft: collapsed ? 80 : 160,
                  }}
                >
                  <NewHeader
                    labelHeader={urlStartsWith.labelHeader}
                    collapsed={collapsed}
                  />
                  {/* <Header headerHeight={headerHeight} username={callBackFunction} /> */}
                  <Content style={{ marginTop: 60 }}>
                    <Switch>
                      {/* <Route path={'/login'} component={Login} /> */}
                      {/* <MainRoute isSignedIn={signedIn} exact path={`${subDomain}/`} /> */}
                      <MainRoute isSignedIn={signedIn} exact path={`/`} />
                      <PrivateRoute
                        isSignedIn={signedIn}
                        exact
                        path={`/project`}
                        render={() => {
                          return (
                            <FeatureFlags.Consumer>
                              {({ features }) => {
                                if (features) {
                                  return (
                                    <ProtectedRoute
                                      redirectPath={`/helpdesk`}
                                      isAllowed={features?.projectViewPage}
                                    >
                                      <Project />
                                    </ProtectedRoute>
                                  );
                                }
                              }}
                            </FeatureFlags.Consumer>
                          );
                        }}
                      />
                      <PrivateRoute
                        isSignedIn={signedIn}
                        exact
                        path={`/leave`}
                        render={() => {
                          return (
                            <FeatureFlags.Consumer>
                              {({ features }) => {
                                if (features) {
                                  return (
                                    <ProtectedRoute
                                      redirectPath={`/helpdesk`}
                                      isAllowed={features?.leaveViewPage}
                                    >
                                      <Leave />
                                    </ProtectedRoute>
                                  );
                                }
                              }}
                            </FeatureFlags.Consumer>
                          );
                        }}
                      />
                      <PrivateRoute
                        isSignedIn={signedIn}
                        exact
                        path={`/holiday`}
                        render={() => {
                          return (
                            <FeatureFlags.Consumer>
                              {({ features }) => {
                                if (features) {
                                  return (
                                    <ProtectedRoute
                                      redirectPath={`/helpdesk`}
                                      isAllowed={features?.holidayViewPage}
                                    >
                                      <Holiday />
                                    </ProtectedRoute>
                                  );
                                }
                              }}
                            </FeatureFlags.Consumer>
                          );
                        }}
                      />

                      <PrivateRoute
                        isSignedIn={signedIn}
                        exact
                        path={`/settings`}
                        render={() => {
                          return (
                            <FeatureFlags.Consumer>
                              {({ features }) => {
                                if (features) {
                                  if (features?.settingsViewPage) {
                                    return <Settings />;
                                  } else {
                                    redirectTo('/helpdesk');
                                  }
                                }
                              }}
                            </FeatureFlags.Consumer>
                          );
                        }}
                      />
                      <PrivateRoute
                        isSignedIn={signedIn}
                        path={`/project/projectform`}
                        render={() => {
                          return (
                            <FeatureFlags.Consumer>
                              {({ features }) => {
                                if (features) {
                                  return (
                                    <ProtectedRoute
                                      redirectPath={`/helpdesk`}
                                      isAllowed={features?.projectViewPage}
                                    >
                                      <ProjectDetail />
                                    </ProtectedRoute>
                                  );
                                }
                              }}
                            </FeatureFlags.Consumer>
                          );
                        }}
                      />
                      <PrivateRoute
                        isSignedIn={signedIn}
                        exact
                        path={`/timesheet`}
                        render={(props) => {
                          return (
                            <FeatureFlags.Consumer>
                              {({ features }) => {
                                if (features) {
                                  if (
                                    userState.currentRoleId !== 2 &&
                                    features?.timesheetViewPage
                                  ) {
                                    return <Timesheet />;
                                  } else if (
                                    userState.currentRoleId === 2 &&
                                    features?.timesheetViewPage
                                  ) {
                                    return (
                                      <TimesheetDetail
                                        {...props}
                                        isViewMode={false}
                                      />
                                    );
                                  } else {
                                    redirectTo('/helpdesk');
                                  }
                                }
                              }}
                            </FeatureFlags.Consumer>
                          );
                        }}
                      />
                      <PrivateRoute
                        isSignedIn={signedIn}
                        exact
                        path={`/timesheet_new`}
                        component={
                          userState.currentRoleId === 1
                            ? TimesheetDetail
                            : TimesheetDetail
                        }
                      />
                      <PrivateRoute
                        isSignedIn={signedIn}
                        exact
                        path={`/timesheet_new`}
                        component={
                          userState.currentRoleId === 1
                            ? TimesheetDetail
                            : TimesheetDetail
                        }
                      />
                      <PrivateRoute
                        isSignedIn={signedIn}
                        exact
                        path={`/timesheetDetail`}
                        render={(props) => {
                          return (
                            <FeatureFlags.Consumer>
                              {({ features }) => {
                                if (features) {
                                  if (
                                    userState.currentRoleId !== 2 &&
                                    features?.timesheetViewPage
                                  ) {
                                    return (
                                      <TimesheetDetail
                                        {...props}
                                        isViewMode={true}
                                      />
                                    );
                                  } else if (
                                    userState.currentRoleId === 2 &&
                                    features?.timesheetViewPage
                                  ) {
                                    return (
                                      <TimesheetDetail
                                        {...props}
                                        isViewMode={false}
                                      />
                                    );
                                  } else {
                                    redirectTo('/helpdesk');
                                  }
                                }
                              }}
                            </FeatureFlags.Consumer>
                          );
                        }}
                      />
                      <PrivateRoute
                        isSignedIn={signedIn}
                        exact
                        path={`/profile`}
                        render={(props) => {
                          const id = new URLSearchParams(
                            props.location.search,
                          ).get('id');
                          return (
                            <FeatureFlags.Consumer>
                              {({ features }) => {
                                if (features) {
                                  return (
                                    <ProtectedRoute
                                      redirectPath={`/helpdesk`}
                                      isAllowed={
                                        userState?.currentRoleId === 2 ||
                                        features?.userViewPage
                                      }
                                    >
                                      <Profile
                                        {...props}
                                        currentPage="profile"
                                      />
                                    </ProtectedRoute>
                                  );
                                }
                              }}
                            </FeatureFlags.Consumer>
                          );
                        }}
                      />

                      <PrivateRoute
                        isSignedIn={signedIn}
                        exact
                        path={`/announce`}
                        render={() => {
                          return (
                            <FeatureFlags.Consumer>
                              {({ features }) => {
                                if (features) {
                                  return (
                                    <ProtectedRoute
                                      redirectPath={`/helpdesk`}
                                      isAllowed={features?.announceViewPage}
                                    >
                                      <Announce />
                                    </ProtectedRoute>
                                  );
                                }
                              }}
                            </FeatureFlags.Consumer>
                          );
                        }}
                      />
                      <PrivateRoute
                        isSignedIn={signedIn}
                        path={`/announce/announceDetail`}
                        render={() => {
                          return (
                            <FeatureFlags.Consumer>
                              {({ features }) => {
                                if (features) {
                                  return (
                                    <ProtectedRoute
                                      redirectPath={`/helpdesk`}
                                      isAllowed={features?.announceViewPage}
                                    >
                                      <AnnounceDetail />
                                    </ProtectedRoute>
                                  );
                                }
                              }}
                            </FeatureFlags.Consumer>
                          );
                        }}
                      />
                      {/* Currently unused page
                      <PrivateRoute
                        isSignedIn={signedIn}
                        exact
                        path={`/customer`}
                        component={Customer}
                      />
                        <PrivateRoute
                        isSignedIn={signedIn}
                        path={`/customer/addcustomer`}
                        component={AddCustomer}
                      />
                      <PrivateRoute
                        isSignedIn={signedIn}
                        path={`$/customer/view/`}
                        component={ViewCustomer}
                      />
                      <PrivateRoute
                        isSignedIn={signedIn}
                        exact
                        path={`/quotation`}
                        component={Quotation}
                      />
                      <PrivateRoute
                        isSignedIn={signedIn}
                        exact
                        path={`/item`}
                        component={AllItem}
                      />
                      <PrivateRoute
                        isSignedIn={signedIn}
                        exact
                        path={`/quotation/createquotation`}
                        component={CreateQuotation}
                      />
                      <PrivateRoute
                        isSignedIn={signedIn}
                        exact
                        path={`/item/additem`}
                        component={AddItem}
                      />
                      <PrivateRoute
                        isSignedIn={signedIn}
                        exact
                        path={`/item/iteminfo`}
                        component={ItemInfo}
                      />
                    */}

                      <PrivateRoute
                        isSignedIn={signedIn}
                        path={`/user/adduser`}
                        render={(props) => {
                          return (
                            <FeatureFlags.Consumer>
                              {({ features }) => {
                                if (features) {
                                  return (
                                    <ProtectedRoute
                                      redirectPath={`/helpdesk`}
                                      isAllowed={features?.userCreate}
                                    >
                                      <Profile {...props} currentPage="add" />
                                    </ProtectedRoute>
                                  );
                                }
                              }}
                            </FeatureFlags.Consumer>
                          );
                        }}
                      />
                      <PrivateRoute
                        isSignedIn={signedIn}
                        path={`/user/view`}
                        render={(props) => {
                          return (
                            <FeatureFlags.Consumer>
                              {({ features }) => {
                                if (features) {
                                  return (
                                    <ProtectedRoute
                                      redirectPath={`/helpdesk`}
                                      isAllowed={
                                        userState.currentRoleId !== 2 ||
                                        features?.userViewPage
                                      }
                                    >
                                      <Profile {...props} currentPage="user" />
                                    </ProtectedRoute>
                                  );
                                }
                              }}
                            </FeatureFlags.Consumer>
                          );
                        }}
                      />
                      <PrivateRoute
                        isSignedIn={signedIn}
                        exact
                        path={`/user`}
                        render={() => {
                          return (
                            <FeatureFlags.Consumer>
                              {({ features }) => {
                                if (features) {
                                  return (
                                    <ProtectedRoute
                                      redirectPath={`/helpdesk`}
                                      isAllowed={features?.userViewPage}
                                    >
                                      <User />
                                    </ProtectedRoute>
                                  );
                                }
                              }}
                            </FeatureFlags.Consumer>
                          );
                        }}
                      />
                      <PrivateRoute
                        isSignedIn={signedIn}
                        exact
                        path={`/user/promotion-upload`}
                        render={() => {
                          return (
                            <FeatureFlags.Consumer>
                              {({ features }) => {
                                if (features) {
                                  return (
                                    <ProtectedRoute
                                      redirectPath={`/helpdesk`}
                                      isAllowed={features?.promotionUpload}
                                    >
                                      <PromotionUploadPage />
                                    </ProtectedRoute>
                                  );
                                }
                              }}
                            </FeatureFlags.Consumer>
                          );
                        }}
                      />

                      <PrivateRoute
                        isSignedIn={signedIn}
                        exact
                        path={`/report`}
                        render={() => {
                          return (
                            <FeatureFlags.Consumer>
                              {({ features }) => {
                                if (features) {
                                  return (
                                    <ProtectedRoute
                                      redirectPath={`/helpdesk`}
                                      isAllowed={features?.reportViewPage}
                                    >
                                      <Report />
                                    </ProtectedRoute>
                                  );
                                }
                              }}
                            </FeatureFlags.Consumer>
                          );
                        }}
                      />
                      <PrivateRoute
                        isSignedIn={signedIn}
                        exact
                        path={`/reference-letter`}
                        render={() => {
                          return (
                            <FeatureFlags.Consumer>
                              {({ features }) => {
                                if (features) {
                                  return (
                                    <ProtectedRoute
                                      redirectPath={`/helpdesk`}
                                      isAllowed={
                                        features?.referenceLetterViewPage
                                      }
                                    >
                                      <ReferenceLetter />
                                    </ProtectedRoute>
                                  );
                                }
                              }}
                            </FeatureFlags.Consumer>
                          );
                        }}
                      />
                      <PrivateRoute
                        isSignedIn={signedIn}
                        exact
                        path={`/claim/medical/request`}
                        render={() => {
                          return (
                            <ProtectedRoute
                              redirectPath={`/helpdesk`}
                              isAllowed={userState.currentRoleId === 2}
                            >
                              <MedicalClaimRequest />
                            </ProtectedRoute>
                          );
                        }}
                      />
                      <PrivateRoute
                        isSignedIn={signedIn}
                        exact
                        path={`/claim/medical/upload`}
                        render={() => {
                          return (
                            <FeatureFlags.Consumer>
                              {({ features }) => {
                                if (features) {
                                  return (
                                    <ProtectedRoute
                                      redirectPath={`/helpdesk`}
                                      isAllowed={
                                        features?.medicalClaimUploadPage
                                      }
                                    >
                                      <MedicalClaimUpload />
                                    </ProtectedRoute>
                                  );
                                }
                              }}
                            </FeatureFlags.Consumer>
                          );
                        }}
                      />
                      <PrivateRoute
                        isSignedIn={signedIn}
                        exact
                        path={`/claim`}
                        render={() => {
                          return (
                            <FeatureFlags.Consumer>
                              {({ features }) => {
                                if (features) {
                                  return (
                                    <ProtectedRoute
                                      redirectPath={`/helpdesk`}
                                      isAllowed={features?.claimViewPage}
                                    >
                                      <Claim />
                                    </ProtectedRoute>
                                  );
                                }
                              }}
                            </FeatureFlags.Consumer>
                          );
                        }}
                      />
                      <PrivateRoute
                        isSignedIn={signedIn}
                        exact
                        path={`/claim/benefit/request`}
                        render={() => {
                          return (
                            <FeatureFlags.Consumer>
                              {({ features }) => {
                                if (features) {
                                  return (
                                    <ProtectedRoute
                                      redirectPath={`/helpdesk`}
                                      isAllowed={features?.claimCreate}
                                    >
                                      {userState.currentRoleId ===
                                      ROLE_ID.User ? (
                                        <BenefitClaimRequest />
                                      ) : (
                                        <BenefitClaimRequestHr />
                                      )}
                                    </ProtectedRoute>
                                  );
                                }
                              }}
                            </FeatureFlags.Consumer>
                          );
                        }}
                      />
                      <PrivateRoute
                        isSignedIn={signedIn}
                        exact
                        path={`/payslip/setting`}
                        render={() => {
                          return (
                            <FeatureFlags.Consumer>
                              {({ features }) => {
                                if (features) {
                                  return (
                                    <ProtectedRoute
                                      redirectPath={`/helpdesk`}
                                      isAllowed={features?.payslipViewPage}
                                    >
                                      <SalarySettingPage />
                                    </ProtectedRoute>
                                  );
                                }
                              }}
                            </FeatureFlags.Consumer>
                          );
                        }}
                      />
                      <PrivateRoute
                        isSignedIn={signedIn}
                        exact
                        path={`/payslip`}
                        render={() => {
                          return (
                            <FeatureFlags.Consumer>
                              {({ features }) => {
                                if (features) {
                                  return (
                                    <ProtectedRoute
                                      redirectPath={`/helpdesk`}
                                      isAllowed={features?.payslipViewPage}
                                    >
                                      {userState.currentRoleId ===
                                      ROLE_ID.User ? (
                                        <PaySlip />
                                      ) : (
                                        <PaySlipAdmin />
                                      )}
                                    </ProtectedRoute>
                                  );
                                }
                              }}
                            </FeatureFlags.Consumer>
                          );
                        }}
                      />
                      <PrivateRoute
                        isSignedIn={signedIn}
                        exact
                        path={`/payslip/upload`}
                        render={() => {
                          return (
                            <FeatureFlags.Consumer>
                              {({ features }) => {
                                if (features) {
                                  return (
                                    <ProtectedRoute
                                      redirectPath={`/helpdesk`}
                                      isAllowed={features?.payslipUpload}
                                    >
                                      <SalaryUploadPage />
                                    </ProtectedRoute>
                                  );
                                }
                              }}
                            </FeatureFlags.Consumer>
                          );
                        }}
                      />
                      <PrivateRoute
                        isSignedIn={signedIn}
                        exact
                        path={`/helpdesk`}
                        component={HelpDesk}
                      />
                      <PrivateRoute
                        isSignedIn={signedIn}
                        exact
                        path={`/role`}
                        render={() => {
                          return (
                            <FeatureFlags.Consumer>
                              {({ features }) => {
                                if (features) {
                                  if (features?.roleViewPage) {
                                    return <UserRole />;
                                  } else {
                                    redirectTo('/helpdesk');
                                  }
                                }
                              }}
                            </FeatureFlags.Consumer>
                          );
                        }}
                      />
                      <PrivateRoute
                        isSignedIn={signedIn}
                        exact
                        path={`/role/roleForm`}
                        render={() => {
                          return (
                            <FeatureFlags.Consumer>
                              {({ features }) => {
                                if (features) {
                                  return (
                                    <ProtectedRoute
                                      redirectPath={`/helpdesk`}
                                      isAllowed={features?.roleViewPage}
                                    >
                                      <UserRoleForm />
                                    </ProtectedRoute>
                                  );
                                }
                              }}
                            </FeatureFlags.Consumer>
                          );
                        }}
                      />
                      {
                        ////////////////////////////// CV Export Section //////////////////////////////
                        // <PrivateRoute
                        //   isSignedIn={signedIn}
                        //   path={`/user/cv`}
                        //   component={(props) => (
                        //     <ProtectedRoute
                        //       redirectPath={`/user`}
                        //       isAllowed={
                        //         userState.currentRole === 'ADMIN' ||
                        //         userState.currentRole === 'ADMIN_HR'
                        //       }
                        //     >
                        //       <CV {...props} currentPage="user" />
                        //     </ProtectedRoute>
                        //   )}
                        // />
                        ////////////////////////////// CV Export Section //////////////////////////////
                      }
                    </Switch>
                  </Content>
                </Layout>
              </Layout>
            </FeatureFlagsProvider>
          </AppProvider>
        </div>
      </>
    );
  }
}

export default App;
