import React, { useContext, useEffect, useState } from 'react';
import { Table, Select, Tag } from '../../components';
import { Layout, Button, Input, Row, Col, message, Form } from 'antd';
import { AppContext } from '../../components/context/AppContext';
import { ReportService, UserService, ImageService } from '../../services/api';
import { MasterDataService } from '../../services/api';
import { DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import profileImage from '../../assets/icons/profileimage.svg';

import { Text } from '../../components';
import { useHistory, NavLink } from 'react-router-dom';
import { FeatureFlags } from '../../components/context/FeatureFlagContext';
import styled from 'styled-components';
import { Circle } from '../../components/Circle';
import { ROLE_ID } from '../../utils/constants';

const { Content } = Layout;

const { Search } = Input;
const { Option } = Select;

const AllUser = () => {
  const { userState } = useContext(AppContext);
  const { features } = useContext(FeatureFlags);

  const [userData, setUserData] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [department, setDepartment] = useState(null);
  const [search, setSearch] = useState(null);
  const [status, setStatus] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isActived, setIsActived] = useState(true);

  const history = useHistory();

  useEffect(() => {
    fetchDepartmentList();
  }, []);

  useEffect(() => {
    fetchAllUser();
  }, [userState, search, department, isActived]);

  const columns = [
    {
      title: 'Emp No.',
      index: 'empCode',
      dataIndex: 'empCode',
      fixed: 'left',
      width: '80px',
      defaultSortOrder: 'ascend',
      sorter: {
        compare: (a, b) => a.empCode.localeCompare(b.empCode),
      },
      render: (empCode, record) => (
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {userState.currentRoleId !== ROLE_ID.User ? (
            <a onClick={() => history.push('/user/view/?id=' + record.userId)}>
              <Text
                sub4
                capitalize
                underline
                className={
                  record.isActived ? 'text-primary' : 'text-placeholder'
                }
              >
                {empCode}
              </Text>
            </a>
          ) : (
            <Text small12 capitalize className="text-normal">
              {empCode}
            </Text>
          )}
        </div>
      ),
    },
    {
      title: 'Level',
      index: 'level',
      dataIndex: 'level',
      width: '80px',
      align: 'center',
      sorter: {
        compare: (a, b) => a.level - b.level,
      },
      render: (level, record) => (
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {record.isActived ? (
            <Tag
              red
              style={{
                width: '40px',
                textAlign: 'center',
                fontSize: '16px',
              }}
            >
              {level}
            </Tag>
          ) : (
            <Tag
              grey
              style={{
                width: '40px',
                textAlign: 'center',
                fontSize: '16px',
              }}
            >
              {level}
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: '',
      index: 'picturePath',
      dataIndex: 'picturePath',
      width: '50px',
      day: 0,
      render: (pic) => {
        return (
          <Circle
            style={{ width: '32px', height: '32px' }}
            onError={(e) => {
              e.target.src = profileImage;
            }}
            src={pic ? pic : profileImage}
          />
        );
      },
    },
    {
      title: 'Nickname',
      index: 'nicknameEn',
      dataIndex: 'nicknameEn',
      width: '100px',
      sorter: {
        compare: (a, b) => a.nicknameEn.localeCompare(b.nicknameEn),
      },
      render: (nicknameEn) => (
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Text small12 capitalize className="text-normal">
            {nicknameEn}
          </Text>
        </div>
      ),
    },
    {
      title: 'Name',
      index: 'firstnameEn',
      dataIndex: 'firstnameEn',
      width: '200px',
      sorter: {
        compare: (a, b) => a.firstnameEn.localeCompare(b.firstnameEn),
      },
      render: (_, record) => (
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Text small12 capitalize className="text-normal">
            {record?.firstnameEn + ' ' + record?.lastnameEn}
          </Text>
        </div>
      ),
    },
    {
      title: 'Position',
      index: 'position',
      dataIndex: 'position',
      width: '180px',
      sorter: {
        compare: (a, b) => a.position.localeCompare(b.position),
      },
      render: (position) => (
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Text small12 className="text-normal">
            {position}
          </Text>
        </div>
      ),
    },
    /*{
      title: 'Department',
      index: 'department',
      dataIndex: 'department',
      width: '200px',
      sorter: {
        compare: (a, b) => a.department.localeCompare(b.department),
      },
      render: (department) => (
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Text small12 className="text-normal">
            {department}
          </Text>
        </div>
      ),
    },*/
    {
      title: 'Mobile',
      index: 'mobile',
      dataIndex: 'mobileNumber',
      width: '100px',
      render: (mobile) => (
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Text small12 className="text-normal">
            {mobile}
          </Text>
        </div>
      ),
    },
    {
      title: 'Email',
      index: 'email',
      dataIndex: 'email',
      width: '220px',
      render: (email) => (
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Text small12 className="text-normal">
            {email}
          </Text>
        </div>
      ),
    },
    ////////////////////////////// CV Export Section //////////////////////////////
    // {
    //   title: 'CV',
    //   index: 'cv',
    //   dataIndex: 'userId',
    //   fixed: 'right',
    //   width: 50,
    //   align: 'center',
    //   render: (userId) =>
    //     !!userId && (
    //       <a>
    //         <img
    //           src={exportIcon}
    //           onClick={(e) =>
    //             this.state.currentRole === 'ADMIN'
    //               ? this.handleRedirect('/user/cv/?id=' + userId)
    //               : {}
    //           }
    //         />
    //       </a>
    //     ),
    // },
    ////////////////////////////// CV Export Section //////////////////////////////
  ];

  const fetchDepartmentList = () => {
    MasterDataService.fetchDepartmentList(
      undefined,
      ({ data }) => {
        setDepartmentList(data);
      },
      (response) => {
        if (response) {
          setIsLoading(false);
          message.error(
            'Failed to fetch department data: ' + response.data.message,
          );
        }
      },
    );
  };

  const fetchAllUser = async () => {
    const payload = {
      department: department,
      search: search,
      isActived: isActived,
    };
    await UserService.fetchUserAll(
      payload,
      async ({ data }) => {
        const tableData = await data.userList.map(async (value) => {
          return value;
        });
        Promise.all(tableData).then((values) => {
          setUserData({ ...data, userList: values });
          setIsLoading(false);
        });
      },
      (response) => {
        if (response) {
          setIsLoading(false);
          message.error(
            'Failed to fetch all user data: ' + response.data.message,
          );
        }
      },
    );
  };

  const handleExportUserReport = (e) => {
    ReportService.fetchUserInfoReport((response) => {
      if (response) {
        message.error({
          content: 'Failed to create report: ' + response.data.message,
        });
      }
    });
  };

  return (
    <div style={{ width: 'auto' }}>
      <Layout style={{ minHeight: 'calc(100vh - 64px)' }}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col flex>
                <Text h4 className="text-normal">
                  User
                </Text>
              </Col>
              <Col flex="auto">
                <Row gutter={[8, 8]} justify="end">
                  {userState.currentRoleId !== ROLE_ID.User && (
                    <Col flex>
                      <Select
                        defaultValue={true}
                        style={{ width: '160px' }}
                        onChange={(value) => {
                          setIsActived(value);
                        }}
                      >
                        <Select.Option value={null}>All Status</Select.Option>
                        <Select.Option value={true}>Active</Select.Option>
                        <Select.Option value={false}>Inactive</Select.Option>
                      </Select>
                    </Col>
                  )}
                  <Col>
                    <Select
                      style={{ width: '200px' }}
                      placeholder="Department"
                      allowClear
                      onChange={(value) => {
                        setDepartment(value);
                      }}
                    >
                      {departmentList.map((dept) => (
                        <Option key={dept.name}>{dept.name}</Option>
                      ))}
                    </Select>
                  </Col>
                  <Col
                    flex="auto"
                    style={{
                      maxWidth: 280,
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Search
                      placeholder="Search"
                      onSearch={(value) => {
                        setSearch(value);
                      }}
                      allowClear={true}
                      style={{ width: '100%', maxWidth: 320 }}
                    />
                  </Col>
                  {features?.userExportReport && (
                    <Col>
                      <Button
                        width="96px"
                        className="button-outlined"
                        onClick={handleExportUserReport}
                        icon={<DownloadOutlined />}
                      >
                        Export
                      </Button>
                    </Col>
                  )}
                  {features?.userCreate && (
                    <Col>
                      <NavLink to={`/user/adduser`}>
                        <Button
                          type="primary"
                          width="96px"
                          className="button-primary"
                          icon={<PlusOutlined />}
                        >
                          Create User
                        </Button>
                      </NavLink>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
          {userState?.currentRoleId !== 2 && (
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col xs={24} md={8}>
                  <Row className="card-container" style={{ margin: 'auto' }}>
                    <Col xs={24} span={1}>
                      <Text small12 className="text-primary">
                        All User
                      </Text>
                    </Col>
                    <Col xs={24}>
                      <Text sub4 className="text-normal">
                        {userData?.totalUser}
                      </Text>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} md={8}>
                  <Row className="card-container" style={{ margin: 'auto' }}>
                    <Col xs={24}>
                      <Text small12 style={{ color: '#08B424' }}>
                        Active
                      </Text>
                    </Col>
                    <Col xs={24}>
                      <Text sub4 className="text-normal">
                        {userData?.totalActive}
                      </Text>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} md={8}>
                  <Row className="card-container" style={{ margin: 'auto' }}>
                    <Col xs={24}>
                      <Text small12 className="text-secondary-red">
                        Inactive
                      </Text>
                    </Col>
                    <Col xs={24}>
                      <Text sub4 className="text-normal">
                        {userData?.totalInactive}
                      </Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          )}
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={
                userState.currentRoleId === ROLE_ID.User
                  ? userData?.userList?.filter((user) => user.isActived)
                  : userData?.userList
              }
              scroll={{
                x: 500,
                y:
                  userState.currentRoleId === ROLE_ID.User
                    ? 'calc(100vh - 240px)'
                    : 'calc(100vh - 340px)',
              }}
              rowKey="id"
              size="small"
              loading={isLoading}
              pagination={{
                position: ['bottomRight'],
                pageSizeOptions: [10, 20, 30, 40],
                showSizeChanger: true,
                defaultPageSize: 20,
              }}
            />
          </Col>
        </Row>
      </Layout>
    </div>
  );
};
export default AllUser;
