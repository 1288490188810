import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../components/context/AppContext';
import { useLocation } from 'react-router-dom';
import { ProjectService, UserService } from '../../services/api';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';
import binIcon from '../../assets/icons/bin.svg';
import actiona from '../../assets/icons/actiona.svg';
import saveIcon from '../../assets/icons/save.svg';
import { FeatureFlags } from '../../components/context/FeatureFlagContext';
import {
  CheckOutlined,
  CloudFilled,
  EditOutlined,
  EyeOutlined,
  LeftOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import {
  AutoComplete,
  Badge,
  Button,
  Col,
  Divider,
  Form,
  Layout,
  Modal,
  Popover,
  Row,
  Spin,
  Tooltip,
  Typography,
  message,
} from 'antd';
import {
  DatePicker,
  FormInput,
  Input,
  Popconfirm,
  Select,
  Switch,
  Table,
  Tag,
  Text,
} from '../../components';
import styled from 'styled-components';
import { NavLink, useHistory, Prompt } from 'react-router-dom';
import CreateOtModal from '../../components/projectform/createOtModal';
import AddMemberProjectModal from '../../components/projectform/addMemberProjectModal';

const { Content } = Layout;

const Circle = styled.div`
  height: ${(props) => (props.small ? '1.375rem' : '2rem')};
  width: ${(props) => (props.small ? '1.375rem' : '2rem')};
  border-radius: 50%;
  background: ${(props) => props.color};

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 99;
`;

const DummyColorCodes = [
  '#0083DE',
  '#F05E6B',
  '#FFAA56',
  '#64B78F',
  '#AF62EB',
  '#055F9D',
];

var jwt = require('jsonwebtoken');
var token = sessionStorage.getItem('access-token');
var decode1 = jwt.decode(token);
const userId = decode1?.userId;

const ProjectDetail = () => {
  const location = useLocation();
  const id = new URLSearchParams(location.search).get('id');
  const { searchData = '', searchType, searchStatus } = location.state || {};
  const isEditMode = Boolean(id);
  const isAddMode = !isEditMode;
  const { userState } = useContext(AppContext);
  const { features } = useContext(FeatureFlags);
  const [form] = useForm();
  const [otForm] = useForm();
  const [memberForm] = useForm();

  const [memberModalVisible, setMemberModalVisible] = useState(false);
  const [otModalVisible, setOtModalVisible] = useState(false);
  const [initialValues, setInitialValues] = useState({
    projectProgressId: 2,
    startDate: moment(),
  });
  const [isFormMode, setIsFormMode] = useState(Boolean(id) ? false : true);
  const [optionsData, setOptionsData] = useState(null);
  const [optionList, setOptionList] = useState(null);
  const [progress, setProgress] = useState([]);
  const [record, setRecord] = useState([]);
  const [isDelete, setIsDelete] = useState(null);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [editModalVisible, setEditModalVisible] = useState();
  const [memberIsActive, setMemberIsActive] = useState(true);
  const [selectedColor, setSelectedColor] = useState(null);
  const [projectTypeList, setProjectTypeList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [existedMemberEmailStatus, setExistedMemberEmailStatus] =
    useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const history = useHistory();

  useEffect(() => {
    handleFetchProjectType();
    if (!isAddMode) {
      getProjectDetail();
      fetchProjects();
    } else {
      form.setFieldsValue({
        startDate: moment(),
      });
      setIsLoading(false);
    }
  }, []);

  const fetchProjects = () => {
    if (userState.currentRoleId === 2) {
      ProjectService.fetchProjectListByUser(
        ({ data }) => {
          if (!data.projectList.some((d) => d.id == id)) {
            message.error("You don't have permission to view this project");
            history.push('/project');
          }
        },
        (response) => {
          if (response) {
            message.error(`Failed to fetch project: ${response.data.message}`);
          }
        },
      );
    }
  };

  const handleSubmit = (values) => {
    const data = {
      projectId: id ? id : undefined,
      projectNo: values.projectNo,
      projectName: values.projectName,
      quotationNo: values.quotationNo,
      poNo: values.poNo,
      contactPerson: values.contactPerson,
      customerName: values.customerName,
      projectDetail: values.projectDetail,
      startDate: initialValues.startDate
        ? moment(initialValues.startDate).format('YYYY-MM-DD')
        : moment(startDate).format('YYYY-MM-DD'),
      endDate: initialValues.endDate
        ? moment(initialValues.endDate).format('YYYY-MM-DD')
        : endDate
        ? moment(endDate).format('YYYY-MM-DD')
        : null,
      projectProgressId: initialValues.projectProgressId,
      isActive: isActive,
      members: initialValues?.members?.map((value) => {
        return {
          userId: Number(value.userId),
          isActive: value.isActive,
          projectRole: value.projectRole,
        };
      }),
      userCurrentRole: null,
      otStatus: initialValues?.otStatus ? initialValues?.otStatus : [],
      projectTypeId: values.projectTypeId,
    };
    if (data.members === undefined) {
      data.members = [];
    }
    if (isAddMode) {
      handleAddProject(data);
    } else {
      handleUpdateProject(data);
    }
  };

  const handleAddProject = (payload) => {
    setIsLoading(true);
    ProjectService.addProject(
      payload,
      ({ data }) => {
        setIsLoading(false);
        setIsChanged(false);
        message.success('Project has been created successfully.');
        setTimeout(() => {
          history.push('/project');
        }, 1000);
      },
      (response) => {
        setIsLoading(false);
        if (response) {
          message.error('Failed to add project: ' + response.data.message);
        }
      },
    );
  };
  const handleUpdateProject = (payload) => {
    setIsLoading(true);
    ProjectService.editProject(
      payload,
      ({ data }) => {
        message.success('Project has been updated successfully.');
        setTimeout(() => {
          setIsFormMode(false);
          setIsChanged(false);
          setIsLoading(false);
          getProjectDetail();
        }, 1000);
      },
      (response) => {
        setIsLoading(false);
        if (response) {
          message.error('Failed to update project: ' + response.data.message);
        }
      },
    );
  };

  const handleFetchProjectType = () => {
    ProjectService.getAllProjectType(
      {
        isActived: true,
      },
      ({ data }) => {
        setProjectTypeList(data.filter((d) => d.isActived));
      },
      (response) => {
        if (response) {
          message.error(
            `Failed to fetch project type: - ${response.data.message}`,
          );
        }
      },
    );
  };

  const cancelEdit = () => {
    getProjectDetail();
    setIsFormMode(false);
  };

  const getProjectDetail = () => {
    const projectId = id;
    setIsLoading(true);
    ProjectService.fetchProject(
      projectId,
      ({ data }) => {
        form.setFieldsValue({
          projectNo: data.projectNo,
          projectName: data.projectName,
          quotationNo: data.quotationNo,
          poNo: data.poNo,
          contactPerson: data.contactPerson,
          customerName: data.customerName,
          projectDetail: data.projectDetail,
          isActive: data.isActive,
          startDate: data.startDate ? moment(data.startDate) : null,
          endDate: data.endDate ? moment(data.endDate) : null,
          projectProgressId: data.projectProgressId,
          projectTypeId: data.projectTypeId,
        });

        setInitialValues(data);
        setIsActive(data.isActive);
        setIsLoading(false);
      },
      (response) => {
        setIsLoading(false);
        if (response) {
          message.error(
            'Failed to fetch project detail: ' + response.data.message,
          );
        }
      },
    );
  };

  const getProjectProgress = () => {
    setIsLoading(true);
    ProjectService.progressProject(
      (data) => {
        setProgress(data.data);
      },
      (response) => {
        setIsLoading(false);
        if (response) {
          message.error(
            'Failed to fetch project progress: ' + response.data.message,
          );
        }
      },
    );
  };

  const updateProjectColor = (projectColorCode) => {
    setIsLoading(true);
    const payload = {
      projectId: id,
      memberId: userId,
      projectColorCode,
      projectColorCode,
    };
    ProjectService.updateProjectColor(
      payload,
      () => {
        message.success('Project color has been updated successfully.');
        setIsLoading(false);
      },
      (response) => {
        setIsLoading(false);
        if (response) {
          message.error(
            'Failed to update project color: ' + response.data.message,
          );
        }
      },
    );
  };

  const submitAddMember = () => {
    const addMember = memberForm.getFieldsValue();
    const members = initialValues.members
      ? initialValues.members.filter((r) => r.userId !== addMember.userId)
      : [];
    setInitialValues({
      ...initialValues,
      members: [...members, addMember],
    });
    setIsChanged(true);
    closeMemberModal();
  };

  const handleOnDeleteMember = (id) => {
    setInitialValues({
      ...initialValues,
      members: initialValues.members.filter((r) => r.userId !== id),
    });
    setIsChanged(true);
    closeMemberModal();
  };

  const submitAddOt = (value) => {
    const otStatus = initialValues.otStatus ? initialValues.otStatus : [];
    setInitialValues({
      ...initialValues,
      otStatus: [...otStatus, value],
    });
    setIsChanged(true);
    closeOtModal();
  };

  const projectSwitchOnChange = (checked) => {
    setInitialValues({
      ...initialValues,
      projectProgressId: checked ? 2 : 3,
    });
    setIsActive(checked);
  };

  const showMemberModal = (record) => {
    setMemberModalVisible(true);
    setRecord(record);
    setIsDelete(record.isDeletable ? record.isDeletable : true);
    setExistedMemberEmailStatus(false);
  };

  const showOtModal = () => {
    setOtModalVisible(true);
  };

  const closeMemberModal = () => {
    setMemberModalVisible(false);
    setOptionsData(null);
    setEditModalVisible(false);
    setRecord(null);

    memberForm.resetFields();
  };

  const closeOtModal = () => {
    setOtModalVisible(false);

    otForm.resetFields();
  };

  const onConfirmDeleteOt = (record) => {
    setInitialValues({
      ...initialValues,
      otStatus: initialValues.otStatus.filter((rec) => rec !== record),
    });
  };

  const renderProgress = () => {
    const progressName = progress
      .map((item) => {
        if (item.id === initialValues.projectProgressId)
          return item.progressName;
      })
      .join('');

    return (
      <Badge
        dot
        color={initialValues.projectProgressId == 2 ? '#20ABC5' : '#E89A3C'}
        text={progressName}
      />
    );
  };
  const OtColumns = [
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      index: 'startDate',
      width: '20%',
      render: (value) => (
        <Text small12 capitalize className="text-normal">
          {moment(value).format('DD/MM/YYYY')}
        </Text>
      ),
    },
    {
      title: 'End Date',
      index: 'endDate',
      dataIndex: 'endDate',
      width: '20%',
      render: (value) => (
        <Text small12 capitalize className="text-normal">
          {moment(value).format('DD/MM/YYYY')}
        </Text>
      ),
    },
    {
      title: 'Action',
      align: 'center',
      width: '7%',
      render: (key, record) =>
        (isFormMode || isAddMode) && (
          <Popconfirm
            title="Are you sure to delete？"
            okText="OK"
            cancelText="Cancel"
            okButtonProps={{
              style: {
                backgroundColor: '#004368',
                borderColor: '##004368',
              },
            }}
            onConfirm={() => onConfirmDeleteOt(record)}
            onCancel={() => {}}
          >
            <img src={binIcon} />
          </Popconfirm>
        ),
    },
  ];

  const memberColumns = [
    {
      title: 'Emp No.',
      dataIndex: 'empCode',
      index: 'empCode',
      width: '120px',
      defaultSortOrder: 'ascend',
      sorter: {
        compare: (a, b) => a.empCode.localeCompare(b.empCode),
      },
      render: (value) => (
        <Text small12 className="text-normal">
          {value}
        </Text>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'firstnameEn',
      index: 'firstnameEn',
      width: '160px',
      render: (name, record) => (
        <Text
          small12
          className="text-normal"
        >{`${record.firstnameEn} ${record.lastnameEn}`}</Text>
      ),
    },
    {
      title: 'Email',
      index: 'email',
      dataIndex: 'email',
      width: '200px',
      render: (value) => (
        <Text small12 className="text-normal">
          {value}
        </Text>
      ),
    },
    {
      title: 'Role',
      index: 'role',
      dataIndex: 'projectRole',
      width: '160px',
      render: (value) => (
        <Text small12 className="text-normal">
          {value}
        </Text>
      ),
    },
    {
      title: 'Position',
      index: 'position',
      dataIndex: 'position',
      width: '160px',
      render: (value) => (
        <Text small12 className="text-normal">
          {value}
        </Text>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      width: '80px',
      render: (value) => (
        <Popover
          content={<span>{value ? 'Active' : 'Inactive'}</span>}
          arrowPointAtCenter
        >
          {value ? (
            <Tag green>
              <Text small12>Active</Text>
            </Tag>
          ) : (
            <Tag grey>
              <Text small12>Inactive</Text>
            </Tag>
          )}
        </Popover>
      ),
    },
    {
      title: 'Action',
      width: '80px',
      align: 'center',
      render: (key, record) =>
        key && (
          <Row justify="center">
            {(features?.projectManageMember && isFormMode) || isAddMode ? (
              <>
                <Col span={10}>
                  <Tooltip mouseEnterDelay={0.4} title="View timesheet">
                    <NavLink
                      onClick={() => {
                        sessionStorage.setItem(
                          'add-projectform-state',
                          JSON.stringify(initialValues),
                        );
                      }}
                      to={'/timesheetDetail/?id=' + String(record.userId)}
                    >
                      <EyeOutlined
                        style={{
                          fontSize: '14px',
                          color: '#004368',
                          cursor: 'pointer',
                          marginBottom: '8px',
                        }}
                      />
                    </NavLink>
                  </Tooltip>
                </Col>
                <Col span={10} offset={4}>
                  <Tooltip mouseEnterDelay={0.4} title="Edit">
                    <a onClick={() => showMemberModal(record)}>
                      <img src={actiona} width={14} height={14} />
                    </a>
                  </Tooltip>
                </Col>
              </>
            ) : (
              <Col span={24}>
                {features?.projectManageMember && (
                  <Tooltip
                    mouseEnterDelay={0.4}
                    mouseLeaveDelay={0}
                    title="View Timesheet"
                  >
                    <NavLink
                      to={'/timesheetDetail/?id=' + String(record.userId)}
                    >
                      <EyeOutlined className="text-primary-blue" />
                    </NavLink>
                  </Tooltip>
                )}
              </Col>
            )}
          </Row>
        ),
    },
  ];

  return (
    <div style={{ width: 'auto' }}>
      <Prompt
        when={isChanged}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      <Spin spinning={isLoading}>
        <Layout style={{ minHeight: 'calc(100vh - 64px)' }}>
          <Content
            style={{
              margin: '32px 20px 10px 20px',
              overflow: 'initial',
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Row gutter={[16, 16]}>
                  <Col flex>
                    <Row gutter={[8, 8]} justify="end">
                      <Col
                        flex
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          history.push('/project', {
                            searchData,
                            searchType,
                            searchStatus,
                          });
                        }}
                      >
                        <LeftOutlined />
                      </Col>
                      <Col flex>
                        <Text h4 className="text-normal">
                          {isAddMode
                            ? 'Create Project'
                            : isFormMode || features?.projectEdit
                            ? 'Edit Project'
                            : 'View Project'}
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Form
                  form={form}
                  onFinish={handleSubmit}
                  initialValues={{
                    projectName: initialValues?.projectName,
                  }}
                  requiredMark={isFormMode}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Row gutter={[16, 16]} className="card-container">
                        <Col span={24} style={{ padding: 0 }}>
                          <Text sub2 className="text-normal">
                            Project Information
                          </Text>
                        </Col>
                        <Row gutter={[16, 16]} style={{ width: '100%' }}>
                          <Col span={12}>
                            <FormInput
                              title="Project No."
                              required={isFormMode}
                              component={
                                <Form.Item
                                  style={{ width: '100%', margin: '0' }}
                                  name="projectNo"
                                  placeholder="No."
                                  rules={[
                                    {
                                      required: isFormMode,
                                      message: 'Please input project no.',
                                    },
                                  ]}
                                >
                                  {isAddMode ? (
                                    <Input
                                      id="projectNo"
                                      placeholder="Project No."
                                      onChange={() => setIsChanged(true)}
                                    />
                                  ) : isFormMode ? (
                                    <Input
                                      id="projectNo"
                                      placeholder="Project No."
                                      disabled={true}
                                    />
                                  ) : (
                                    <Text
                                      small12
                                      className="text-normal"
                                      style={{ width: '100%', margin: '0' }}
                                    >
                                      {initialValues?.projectNo}
                                    </Text>
                                  )}
                                </Form.Item>
                              }
                            />
                          </Col>
                          <Col span={12}>
                            <FormInput
                              title="Project Name"
                              required={isFormMode}
                              component={
                                <Form.Item
                                  style={{ width: '100%', margin: '0' }}
                                  name="projectName"
                                  placeholder="Name"
                                  rules={[
                                    {
                                      required: isFormMode,
                                      message: 'Please input project name.',
                                    },
                                  ]}
                                >
                                  {isFormMode || isAddMode ? (
                                    <Input
                                      id="projectName"
                                      placeholder="Project Name"
                                      onChange={() => setIsChanged(true)}
                                    />
                                  ) : (
                                    <Text
                                      small12
                                      className="text-normal"
                                      style={{ width: '100%', margin: '0' }}
                                    >
                                      {initialValues?.projectName}
                                    </Text>
                                  )}
                                </Form.Item>
                              }
                            />
                          </Col>
                        </Row>
                        <Row gutter={[16, 16]} style={{ width: '100%' }}>
                          <Col span={12}>
                            <FormInput
                              title="Ref Quotation No."
                              component={
                                <Form.Item
                                  style={{ width: '100%', margin: '0' }}
                                  name="quotationNo"
                                >
                                  {isFormMode || isAddMode ? (
                                    <Input
                                      id="quotationNo"
                                      placeholder="Quotation No."
                                      onChange={() => setIsChanged(true)}
                                    />
                                  ) : (
                                    <Text
                                      small12
                                      className="text-normal"
                                      style={{ width: '100%', margin: '0' }}
                                    >
                                      {initialValues?.quotationNo || '-'}
                                    </Text>
                                  )}
                                </Form.Item>
                              }
                            />
                          </Col>
                          <Col span={12}>
                            <FormInput
                              title="Ref PO No."
                              component={
                                <Form.Item
                                  style={{ width: '100%', margin: '0' }}
                                  name="poNo"
                                >
                                  {isFormMode || isAddMode ? (
                                    <Input
                                      id="poNo"
                                      placeholder="PO No."
                                      onChange={() => setIsChanged(true)}
                                    />
                                  ) : (
                                    <Text
                                      small12
                                      className="text-normal"
                                      style={{ width: '100%', margin: '0' }}
                                    >
                                      {initialValues?.poNo || '-'}
                                    </Text>
                                  )}
                                </Form.Item>
                              }
                            />
                          </Col>
                        </Row>
                        <Row gutter={[16, 16]} style={{ width: '100%' }}>
                          <Col span={12}>
                            <FormInput
                              title="Project Type"
                              required={isFormMode}
                              component={
                                <Form.Item
                                  style={{ width: '100%', margin: '0' }}
                                  name="projectTypeId"
                                  rules={[
                                    {
                                      required: isFormMode,
                                      message: 'Please select project type.',
                                    },
                                  ]}
                                >
                                  {isFormMode || isAddMode ? (
                                    <Select
                                      placeholder="Select project type"
                                      onChange={() => setIsChanged(true)}
                                    >
                                      {projectTypeList?.map((record) => (
                                        <Select.Option value={record.id}>
                                          {record.projectTypeName}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  ) : (
                                    <Text
                                      small12
                                      className="text-normal"
                                      style={{ width: '100%', margin: '0' }}
                                    >
                                      {initialValues?.projectTypeName}
                                    </Text>
                                  )}
                                </Form.Item>
                              }
                            />
                          </Col>
                          <Col span={12}>
                            <FormInput
                              title="Customer Name"
                              required={isFormMode}
                              component={
                                <Form.Item
                                  style={{ width: '100%', margin: '0' }}
                                  name="customerName"
                                  rules={[
                                    {
                                      required: isFormMode,
                                      message: 'Please input customer name.',
                                    },
                                  ]}
                                >
                                  {isFormMode || isAddMode ? (
                                    <Input
                                      id="customerName"
                                      placeholder="Name"
                                      onChange={() => setIsChanged(true)}
                                    />
                                  ) : (
                                    <Text
                                      small12
                                      className="text-normal"
                                      style={{ width: '100%', margin: '0' }}
                                    >
                                      {initialValues?.customerName}
                                    </Text>
                                  )}
                                </Form.Item>
                              }
                            />
                          </Col>
                        </Row>
                        <Row gutter={[16, 16]} style={{ width: '100%' }}>
                          <Col span={12}>
                            <FormInput
                              title="Contact Person"
                              component={
                                <Form.Item
                                  style={{ width: '100%', margin: '0' }}
                                  name="contactPerson"
                                >
                                  {isFormMode || isAddMode ? (
                                    <Input
                                      id="contactPerson"
                                      placeholder="Name"
                                      onChange={() => setIsChanged(true)}
                                    />
                                  ) : (
                                    <Text
                                      small12
                                      className="text-normal"
                                      style={{ width: '100%', margin: '0' }}
                                    >
                                      {initialValues?.contactPerson}
                                    </Text>
                                  )}
                                </Form.Item>
                              }
                            />
                          </Col>
                          {userState.currentRoleId === 2 && (
                            <Col span={12}>
                              <FormInput
                                title="Project Color (For display in timesheet)"
                                component={
                                  <Row
                                    gutter={[8, 8]}
                                    style={{ width: '100%' }}
                                  >
                                    {DummyColorCodes.map((color) => (
                                      <Col>
                                        <a
                                          onClick={() => {
                                            setSelectedColor(color);
                                            updateProjectColor(color);
                                          }}
                                        >
                                          <Circle
                                            color={color}
                                            style={
                                              selectedColor === color
                                                ? {
                                                    border: '2px solid #FFFFFF',
                                                    boxShadow:
                                                      '0px 0px 8px #00000066',
                                                  }
                                                : undefined
                                            }
                                          >
                                            {selectedColor === color ? (
                                              <CheckOutlined
                                                style={{
                                                  color: '#FFFFFF',
                                                }}
                                              />
                                            ) : (
                                              <></>
                                            )}
                                          </Circle>
                                        </a>
                                      </Col>
                                    ))}
                                  </Row>
                                }
                              />
                            </Col>
                          )}
                        </Row>
                        <Row gutter={[16, 16]} style={{ width: '100%' }}>
                          <Col span={24}>
                            <FormInput
                              title="Project Description"
                              required={isFormMode}
                              component={
                                <Form.Item
                                  style={{
                                    width: '100%',
                                    margin: '0',
                                  }}
                                  name="projectDetail"
                                  rules={[
                                    {
                                      required: isFormMode,
                                      message:
                                        'Please input project description.',
                                    },
                                  ]}
                                >
                                  {isFormMode || isAddMode ? (
                                    <Input
                                      id="projectDetail"
                                      placeholder="Details"
                                      onChange={() => setIsChanged(true)}
                                    />
                                  ) : (
                                    <Text
                                      small12
                                      className="text-normal"
                                      style={{ width: '100%', margin: '0' }}
                                    >
                                      {initialValues?.projectDetail}
                                    </Text>
                                  )}
                                </Form.Item>
                              }
                            />
                          </Col>
                        </Row>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row gutter={[16, 16]} className="card-container">
                        <Col span={24} style={{ padding: 0 }}>
                          <Text sub2 className="text-normal">
                            Duration & Status
                          </Text>
                        </Col>
                        {/*<Col span={24}>
                        <Row gutter={[16, 16]} justify="space-between">
                            <Text sub2 className="text-normal">
                            Project Details
                            </Text>
                            {initialValues.projectProgressId == 2 ? (
                            <Tag green>Active</Tag>
                            ) : (
                            <Tag grey>Inactive</Tag>
                            )}
                        </Row>
                        </Col>*/}
                        <Row gutter={[16, 16]} style={{ width: '100%' }}>
                          <Col span={12}>
                            <FormInput
                              title="Start Date"
                              required={isFormMode}
                              component={
                                <Form.Item
                                  style={{
                                    width: '50%',
                                    margin: '0',
                                    paddingRight: '2rem',
                                  }}
                                  name="startDate"
                                  rules={[
                                    {
                                      required: isFormMode,
                                      message: 'Please select start date.',
                                    },
                                  ]}
                                >
                                  {isFormMode || isAddMode ? (
                                    <DatePicker
                                      placeholder="Select start date"
                                      format="DD/MM/YYYY"
                                      onChange={(value) => {
                                        setInitialValues({
                                          ...initialValues,
                                          startDate: value,
                                        });
                                        setStartDate(value);
                                        setIsChanged(true);
                                      }}
                                    />
                                  ) : (
                                    <Text
                                      small12
                                      className="text-normal"
                                      style={{ width: '100%', margin: '0' }}
                                    >
                                      {initialValues.startDate
                                        ? moment(
                                            initialValues?.startDate,
                                          ).format('DD/MM/YYYY')
                                        : ''}
                                    </Text>
                                  )}
                                </Form.Item>
                              }
                            />
                          </Col>
                          <Col span={12}>
                            <FormInput
                              title="End Date"
                              component={
                                <Form.Item
                                  style={{
                                    width: '50%',
                                    margin: '0',
                                    paddingRight: '2rem',
                                  }}
                                  name="endDate"
                                  rules={[
                                    () => ({
                                      validator: (_, value) => {
                                        if (
                                          value &&
                                          moment(
                                            form.getFieldValue('startDate'),
                                          ) > moment(value)
                                        ) {
                                          return Promise.reject(
                                            'End Date must be greater than Start Date.',
                                          );
                                        }
                                        return Promise.resolve();
                                      },
                                    }),
                                  ]}
                                >
                                  {isFormMode || isAddMode ? (
                                    <DatePicker
                                      id="endDate"
                                      placeholder="Select end date"
                                      disabledDate={(current) =>
                                        current <
                                        moment(form.getFieldValue('startDate'))
                                      }
                                      format="DD/MM/YYYY"
                                      defaultValue={
                                        initialValues.endDate
                                          ? moment(initialValues.endDate)
                                          : ''
                                      }
                                      onChange={(value) => {
                                        setInitialValues({
                                          ...initialValues,
                                          endDate: value,
                                        });
                                        setEndDate(value);
                                        setIsChanged(true);
                                      }}
                                    />
                                  ) : (
                                    <Text
                                      small12
                                      className="text-normal"
                                      style={{ width: '100%', margin: '0' }}
                                    >
                                      {initialValues.endDate
                                        ? moment(initialValues?.endDate).format(
                                            'DD/MM/YYYY',
                                          )
                                        : ''}
                                    </Text>
                                  )}
                                </Form.Item>
                              }
                            />
                          </Col>
                        </Row>
                        <Row gutter={[16, 16]} style={{ width: '100%' }}>
                          <Col span={12}>
                            <FormInput
                              title="Project Status"
                              component={
                                <Form.Item
                                  style={{ width: '100%', margin: '0' }}
                                  name="isActive"
                                  valuePropName="checked"
                                >
                                  <Switch
                                    checkedChildren="Active"
                                    unCheckedChildren="Inactive"
                                    checked={isActive}
                                    onChange={(checked) => {
                                      projectSwitchOnChange(checked);
                                      setIsChanged(true);
                                    }}
                                    disabled={!(isFormMode || isAddMode)}
                                  />
                                </Form.Item>
                              }
                            />
                          </Col>
                          {/*<Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <FormInput
                                title="Progress"
                                component={
                                isFormMode || isAddMode ? (
                                    <Input
                                    prefix={
                                        <Badge
                                        dot
                                        color={
                                            initialValues.projectProgressId == 2
                                            ? '#20ABC5'
                                            : '#E89A3C'
                                        }
                                        />
                                    }
                                    value={progress
                                        .map((item) => {
                                        if (
                                            item.id ===
                                            initialValues.projectProgressId
                                        ) {
                                            return item.progressName;
                                        }
                                        })
                                        .join('')}
                                    disabled
                                    defaultValue={initialValues.projectProgressId}
                                    style={{
                                        width: '100%',
                                        height: '2rem',
                                    }}
                                    />
                                ) : (
                                    <Text
                                    small12
                                    className="text-normal"
                                    style={{ width: '100%', margin: '0' }}
                                    >
                                    {renderProgress()}
                                    </Text>
                                )
                                }
                            />
                            </Col>*/}
                        </Row>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col span={24}>
                <Row gutter={[16, 16]} className="card-container">
                  <Col flex>
                    <Text sub2 className="text-normal">
                      OT Status
                    </Text>
                  </Col>
                  <Col flex="auto">
                    <Row gutter={[8, 8]} justify="end">
                      {(isFormMode || isAddMode) &&
                        features?.projectManageOTDate && (
                          <Button
                            type="primary"
                            className="button-primary"
                            width="149px"
                            onClick={showOtModal}
                          >
                            Create OT Date
                          </Button>
                        )}
                    </Row>
                  </Col>

                  <Col span={24}>
                    <Table
                      columns={OtColumns}
                      dataSource={initialValues ? initialValues.otStatus : null}
                      size="small"
                      pagination={false}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[16, 16]} className="card-container">
                  <Col flex>
                    <Text sub2 className="text-normal">
                      Member
                    </Text>
                  </Col>
                  <Col flex="auto">
                    <Row gutter={[8, 8]} justify="end">
                      {(isFormMode || isAddMode) &&
                        features?.projectManageMember && (
                          <Button
                            type="primary"
                            className="button-primary"
                            width="149px"
                            onClick={showMemberModal}
                          >
                            <UserAddOutlined /> Create Member
                          </Button>
                        )}
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Table
                      columns={memberColumns}
                      dataSource={initialValues ? initialValues.members : null}
                      size="small"
                      pagination={false}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[8, 8]} justify="end">
                  {isFormMode || isAddMode ? (
                    <>
                      {isAddMode ? (
                        <Col flex>
                          <NavLink to={'/project'}>
                            <Button className="button-outlined" width="76px">
                              Cancel
                            </Button>
                          </NavLink>
                        </Col>
                      ) : (
                        <Col flex>
                          {isChanged ? (
                            <Popconfirm
                              placement="topRight"
                              title="You have unsaved changes. Are you sure you want to cancel"
                              okButtonProps={{
                                style: {
                                  backgroundColor: '#004368',
                                  borderColor: '#2d95a8',
                                },
                              }}
                              onConfirm={() => {
                                setIsFormMode(false);
                                setIsChanged(false);
                                getProjectDetail();
                              }}
                              onCancel={() => {}}
                            >
                              <Button className="button-outlined">
                                Cancel
                              </Button>
                            </Popconfirm>
                          ) : (
                            <Button
                              onClick={() => {
                                setIsFormMode(false);
                                getProjectDetail();
                              }}
                              className="button-outlined"
                            >
                              Cancel
                            </Button>
                          )}
                        </Col>
                      )}
                      <Col flex>
                        <Button
                          type="primary"
                          className="button-primary"
                          width="76px"
                          onClick={() => form.submit()}
                        >
                          Submit
                        </Button>
                      </Col>
                    </>
                  ) : (
                    <>
                      {features?.projectEdit && (
                        <Col flex>
                          <Button
                            type="primary"
                            className="button-primary"
                            width="96px"
                            onClick={() => setIsFormMode(true)}
                          >
                            <EditOutlined /> Edit
                          </Button>
                        </Col>
                      )}
                    </>
                  )}
                </Row>
              </Col>
            </Row>
            <CreateOtModal
              otFormRef={otForm}
              submitAddOt={submitAddOt}
              otModalVisible={otModalVisible}
              closeOtModal={closeOtModal}
            />
            <AddMemberProjectModal
              memberModalVisible={memberModalVisible}
              closeMemberModal={closeMemberModal}
              memberForm={memberForm}
              submitAddMember={submitAddMember}
              record={record}
              initialValues={initialValues}
              setInitialValues={setInitialValues}
              setIsChanged={setIsChanged}
              isAddMode={isAddMode}
              onDeleteMember={handleOnDeleteMember}
            />
          </Content>
        </Layout>
      </Spin>
    </div>
  );
};
export default ProjectDetail;
