import {
  AutoComplete,
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Text } from '../Text';
import { UserService } from '../../services/api';
import FormInput from '../FormInput';
import { Switch } from '../Switch';

const AddMemberProjectModal = (props) => {
  const {
    memberModalVisible,
    closeMemberModal,
    memberForm,
    submitAddMember,
    record,
    initialValues,
    onDeleteMember,
    isAddMode,
  } = props;

  const [optionList, setOptionList] = useState(null);
  const [optionsData, setOptionsData] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [memberIsActive, setMemberIsActive] = useState(true);
  const [existedMemberEmailStatus, setExistedMemberEmailStatus] =
    useState(false);

  useEffect(() => {
    getUserOptions();
  }, [record]);

  const getUserOptions = () => {
    UserService.getUserOptions(
      {
        isActived: true,
      },
      ({ data }) => {
        setOptionsData(data);
      },
      (error) => {
        if (error) message.error(`Error: ${error.data.message}`);
      },
    );
    if (record?.userId) {
      setEditModalVisible(true);
      setMemberIsActive(record?.isActive);
      memberForm.setFieldsValue({
        userId: record?.userId,
        email: record?.email,
        firstnameEn: record?.firstnameEn,
        lastnameEn: record?.lastnameEn,
        name: record?.firstnameEn + ' ' + record?.lastnameEn,
        nickname: record?.nicknameEn,
        empCode: record?.empCode,
        position: record?.position,
        department: record?.department,
        projectRole: record?.projectRole,
        isActive: record?.isActive,
      });
    } else {
      memberForm.setFieldsValue({
        isActive: true,
      });
    }
  };

  const selectOptions = (search) => {
    const selectedUser = optionsData.find((option) => option.email === search);
    memberForm.setFieldsValue({
      userId: selectedUser.userId,
      email: selectedUser.email,
      firstnameEn: selectedUser.firstnameEn,
      lastnameEn: selectedUser.lastnameEn,
      name: selectedUser.firstnameEn + ' ' + selectedUser.lastnameEn,
      nickname: selectedUser.nicknameEn,
      empCode: selectedUser.empCode,
      position: selectedUser.position,
      department: selectedUser.department,
      projectRole: '',
    });
  };

  const getOptions = (search) => {
    let isSearchThai = search && /[\u0E00-\u0E7F]/g.test(search);
    const emailList = optionsData
      ?.filter(
        (optionData) =>
          (optionData.email &&
            optionData.email.toLowerCase().includes(search.toLowerCase())) ||
          (optionData.firstnameEn &&
            optionData.firstnameEn
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (optionData.lastnameEn &&
            optionData.lastnameEn
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (optionData.nicknameEn &&
            optionData.nicknameEn
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (optionData.firstnameTh && optionData.firstnameTh.includes(search)) ||
          (optionData.lastnameTh && optionData.lastnameTh.includes(search)) ||
          (optionData.nicknameTh && optionData.nicknameTh.includes(search)),
      )
      .map((res) => {
        const getHighlightedText = (text) => {
          if (text !== null) {
            let searchCriteria = text;
            let index = searchCriteria
              .toLowerCase()
              .indexOf(search.toLowerCase());

            if (search.length != 0 && index !== -1) {
              let prefix = searchCriteria.substr(0, index);
              let subfix = searchCriteria.substr(index + search.length);
              let matched = searchCriteria.substr(index, search.length);
              return (
                <>
                  {prefix}
                  <span style={{ fontWeight: 'bold', fontColor: '#004368' }}>
                    {matched}
                  </span>
                  {subfix}
                </>
              );
            } else if (
              !isSearchThai &&
              (res.firstnameEn
                .toLowerCase()
                .indexOf(search.toLowerCase().trim()) !== -1 ||
                res.lastnameEn
                  .toLowerCase()
                  .indexOf(search.toLowerCase().trim()) !== -1 ||
                res.email.toLowerCase().indexOf(search.toLowerCase().trim()) !==
                  -1 ||
                res.nicknameEn
                  .toLowerCase()
                  .indexOf(search.toLowerCase().trim()) !== -1)
            ) {
              return (
                [
                  res.firstnameEn,
                  res.lastnameEn,
                  res.nicknameEn,
                  res.email,
                ].includes(text) && text
              );
            } else {
              return (
                [
                  res.firstnameTh,
                  res.lastnameTh,
                  res.nicknameTh,
                  res.email,
                ].includes(text) && text
              );
            }
          }
        };

        return {
          value: res.email,
          label: (
            <Row>
              <Col span={15}>
                <Typography.Title
                  style={{
                    fontWeight: 'normal',
                    fontSize: '15px',
                  }}
                  level={5}
                  ellipsis={{
                    rows: 1,
                  }}
                >
                  {getHighlightedText(res.firstnameEn)}
                  {getHighlightedText(res.firstnameTh)}{' '}
                  {getHighlightedText(res.lastnameEn)}
                  {getHighlightedText(res.lastnameTh)}
                </Typography.Title>

                <Typography.Text
                  style={{
                    fontWeight: 'normal',
                    fontSize: '12px',
                  }}
                  ellipsis={{
                    rows: 1,
                  }}
                >
                  {getHighlightedText(res.email)}
                </Typography.Text>
              </Col>
              <Col span={5} offset={3}>
                <Typography.Title
                  style={{
                    fontWeight: 'normal',
                    fontSize: '15px',
                    textAlign: 'left',
                  }}
                  level={5}
                  ellipsis={{
                    rows: 1,
                  }}
                >
                  {getHighlightedText(res.nicknameEn)}
                  {getHighlightedText(res.nicknameTh)}
                </Typography.Title>
              </Col>
            </Row>
          ),
        };
      });
    setOptionList(emailList);

    if (initialValues.members !== undefined) {
      initialValues.members.map((member) => {
        if (search === member.email) {
          return setExistedMemberEmailStatus(true);
        }
      });
    }
  };

  const memberSwitchOnChange = (checked) => {
    setMemberIsActive(checked);
  };

  const handleOnDeleteMember = (userId) => {
    onDeleteMember(userId);
    setEditModalVisible(false);
  };

  return (
    <Modal
      width={500}
      bodyStyle={{
        overflowY: 'auto',
        maxHeight: '500px',
      }}
      open={memberModalVisible}
      onCancel={() => {
        setEditModalVisible(false);
        closeMemberModal();
      }}
      centered
      footer={
        <Col span={24}>
          <Row gutter={[8, 8]} justify="space-between">
            <Col flex>
              {record?.isDeletable ? (
                <Button
                  className="button-red"
                  onClick={() => handleOnDeleteMember(record?.userId)}
                >
                  Delete
                </Button>
              ) : (
                // <Button
                //   className="button-outlined"
                //   onClick={() => {
                //     setEditModalVisible(false);
                //     closeMemberModal();
                //   }}
                // >
                //   Cancel
                // </Button>
                <></>
              )}
            </Col>
            <Col flex>
              <Button
                className="button-outlined"
                onClick={() => {
                  setEditModalVisible(false);
                  closeMemberModal();
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                className="button-primary"
                onClick={() => {
                  memberForm.setFieldsValue({
                    isActive: memberIsActive,
                  });
                  setEditModalVisible(false);
                  memberForm.submit();
                }}
              >
                {editModalVisible ? 'Save' : 'Create'}
              </Button>
            </Col>
          </Row>
        </Col>
      }
    >
      <Form form={memberForm} onFinish={submitAddMember} requiredMark={true}>
        <Row>
          <Text h4 className="text-normal">
            {editModalVisible ? 'Edit Member' : 'Create Member'}
          </Text>
        </Row>
        <Divider style={{ margin: '12px 0' }} />
        <Row gutter={[16, 8]} style={{ overflowY: 'auto' }}>
          <Col span={24}>
            <Form.Item name="userId" hidden />
            <FormInput
              title="Email"
              required
              component={
                <Form.Item
                  id="email"
                  style={{ width: '100%', margin: 0 }}
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Please input Email',
                    },
                  ]}
                >
                  <AutoComplete
                    options={optionList}
                    onSelect={selectOptions}
                    onChange={getOptions}
                    placeholder="Search by Email, Name, Nick Name"
                    disabled={editModalVisible}
                    status={
                      existedMemberEmailStatus === true ? 'warning' : undefined
                    }
                    filterOption={(inputValue, option) => {
                      if (initialValues.members !== undefined) {
                        return !initialValues.members.some(
                          (member) => option.value === member.email,
                        );
                      } else {
                        return true;
                      }
                    }}
                  />
                </Form.Item>
              }
            />
          </Col>
          <Col span={24}>
            <FormInput
              title="Name"
              required
              component={
                <Form.Item style={{ width: '100%', margin: 0 }} name="name">
                  <Input id="name" placeholder="Name" disabled={true} />
                </Form.Item>
              }
            />
          </Col>
          <Col span={12}>
            <FormInput
              title="Emp No."
              required
              component={
                <Form.Item style={{ width: '100%', margin: 0 }} name="empCode">
                  <Input id="empCode" placeholder="Emp No." disabled={true} />
                </Form.Item>
              }
            />
          </Col>
          <Col span={12}>
            <FormInput
              title="Nickname"
              required
              component={
                <Form.Item style={{ width: '100%', margin: 0 }} name="nickname">
                  <Input id="nickname" placeholder="Nickname" disabled={true} />
                </Form.Item>
              }
            />
          </Col>
          <Col span={12}>
            <FormInput
              title="Position"
              required
              component={
                <Form.Item style={{ width: '100%', margin: 0 }} name="position">
                  <Input id="position" placeholder="Position" disabled={true} />
                </Form.Item>
              }
            />
          </Col>
          <Col span={12}>
            <FormInput
              title="Status"
              required
              component={
                <Form.Item
                  style={{ width: '100%', margin: 0 }}
                  name="isActive"
                  valuePropName="checked"
                >
                  <Switch
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    checked={memberIsActive}
                    onChange={(checked) => memberSwitchOnChange(checked)}
                  />
                </Form.Item>
              }
            />
          </Col>
          <Col span={24}>
            <FormInput
              title="Role (In this project)"
              required
              component={
                <Form.Item
                  style={{ width: '100%', margin: 0 }}
                  name="projectRole"
                  rules={[
                    {
                      required: true,
                      message: 'Please input role',
                    },
                  ]}
                >
                  <Input id="projectRole" placeholder="Role" />
                </Form.Item>
              }
            />
          </Col>
          <Form.Item
            style={{ width: '100%', margin: 0 }}
            name="firstnameEn"
            hidden={true}
          >
            <Input id="firstnameEn" />
          </Form.Item>
          <Form.Item
            style={{ width: '100%', margin: 0 }}
            name="lastnameEn"
            hidden={true}
          >
            <Input id="lastnameEn" hidden={true} />
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
};
export default AddMemberProjectModal;
