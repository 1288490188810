import React, { useState, useEffect, useContext } from 'react';
import { Button, Input, Row, Col, Layout, Alert } from 'antd';

import { UploadOutlined } from '@ant-design/icons';
import { PromotionHistoryService } from '../../services/api';
import { AppContext } from '../../components/context/AppContext';
import { NavLink } from 'react-router-dom';
import { Table, Text } from '../../components';
import { FeatureFlags } from '../../components/context/FeatureFlagContext';
import { useLocation } from 'react-router-dom';
import { filterBySearchValue } from '../../services/search';

const { Search } = Input;

const PromoteViewPage = () => {
  const location = useLocation();
  const { userState } = useContext(AppContext);
  const { features } = useContext(FeatureFlags);
  const [data, setData] = useState([]);
  const [dataMain, setDataMain] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [searchWord, setSearchWord] = useState(
    location?.state?.searchData || '',
  );

  const defaultStringCompare = (property) => (a, b) =>
    String(a[property]).localeCompare(String(b[property]));

  const columns = [
    {
      title: 'Emp No.',
      key: 'empCode',
      dataIndex: 'empCode',
      width: '100px',
      fixed: 'left',
      sorter: defaultStringCompare('empCode'),
      render: (value) => (
        <Text small12 capitalize className="text-normal">
          {value}
        </Text>
      ),
    },
    {
      title: 'Full Name',
      key: 'fullName',
      dataIndex: 'empCodeToEmpCode',
      fixed: 'left',
      sorter: (a, b) => {
        const nameA = `${a.empCodeToEmpCode.firstnameTh} ${a.empCodeToEmpCode.lastnameTh}`;
        const nameB = `${b.empCodeToEmpCode.firstnameTh} ${b.empCodeToEmpCode.lastnameTh}`;
        return nameA.localeCompare(nameB);
      },
      render: (value) => (
        <Text small12 capitalize className="text-normal">
          {value.firstnameTh} {value.lastnameTh}
        </Text>
      ),
    },
    {
      title: 'Type',
      key: 'type',
      fixed: 'left',
      sorter: defaultStringCompare('type'),
      render: (_, record) => (
        <Text small12 capitalize className="text-normal">
          {record.level && record.positionId
            ? `เลื่อนขั้นและเปลี่ยนตำแหน่ง`
            : record.level
            ? `เลื่อนขั้น`
            : `เปลี่ยนตำแหน่ง`}
        </Text>
      ),
    },
    {
      title: 'New Level',
      key: 'level',
      dataIndex: 'levelToLevelNo',
      width: '100px',
      align: 'center',
      sorter: defaultStringCompare('level'),
      render: (value) => (
        <Text small12 capitalize className="text-normal">
          {value?.levelNo ? <a>{value.levelNo}</a> : '-'}
        </Text>
      ),
    },
    {
      title: 'New Position',
      key: 'positionId',
      dataIndex: 'positionToPositionId',
      width: '200px',
      align: 'center',
      sorter: defaultStringCompare('positionId'),
      render: (value) => (
        <Text small12 capitalize className="text-normal">
          {value?.positionName ? <a>{value.positionName}</a> : '-'}
        </Text>
      ),
    },
    {
      title: 'New Department',
      key: 'departmentId',
      dataIndex: 'departmentToDepartmentId',
      width: '200px',
      align: 'center',
      sorter: defaultStringCompare('departmentId'),
      render: (value) => (
        <Text small12 capitalize className="text-normal">
          {value?.name ? <a>{value.name}</a> : '-'}
        </Text>
      ),
    },
    {
      title: 'Level',
      key: 'level',
      dataIndex: 'empCodeToEmpCode',
      width: '100px',
      align: 'center',
      sorter: defaultStringCompare('level'),
      render: (value) => (
        <Text small12 capitalize className="text-normal">
          {value.levelToLevelNo.levelNo}
        </Text>
      ),
    },
    {
      title: 'Position',
      key: 'positionId',
      dataIndex: 'empCodeToEmpCode',
      width: '200px',
      align: 'center',
      sorter: defaultStringCompare('positionId'),
      render: (value) => (
        <Text small12 capitalize className="text-normal">
          {value.positionToPositionId.positionName}
        </Text>
      ),
    },
    {
      title: 'Department',
      key: 'departmentId',
      dataIndex: 'empCodeToEmpCode',
      width: '200px',
      align: 'center',
      sorter: defaultStringCompare('departmentId'),
      render: (value) => (
        <Text small12 capitalize className="text-normal">
          {value.departmentToDepartmentId.name}
        </Text>
      ),
    },
  ].filter(Boolean);

  const fetchPromotionHistory = () => {
    PromotionHistoryService.fetchPromotionHistory(({ data }) => {
      setData(data);
      setDataMain(data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchPromotionHistory();
  }, []);

  const onSearch = (value) => {
    setData(
      dataMain.filter((data) => {
        if (!value) {
          return true;
        } else {
          return (
            String(data.empCode).toUpperCase().includes(value.toUpperCase()) ||
            String(data.empCodeToEmpCode.firstnameTh)
              .toUpperCase()
              .includes(value.toUpperCase()) ||
            String(data.empCodeToEmpCode.lastnameTh)
              .toUpperCase()
              .includes(value.toUpperCase())
          );
        }
      }),
    );
  };

  return (
    <div style={{ width: 'auto' }}>
      <Layout style={{ minHeight: 'calc(100vh - 64px)' }}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col flex>
                <Text h4 className="text-normal">
                  Promotion
                </Text>
              </Col>
              <Col flex="auto">
                <Row gutter={[8, 8]} justify="end">
                  <Col
                    flex="auto"
                    style={{
                      maxWidth: 320,
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Search
                      placeholder="Search"
                      value={searchWord}
                      onSearch={onSearch}
                      onChange={(e) => setSearchWord(e.target.value)}
                      allowClear={true}
                      style={{ width: '100%', maxWidth: 320 }}
                    />
                  </Col>
                  {features?.promotionUpload && (
                    <Col>
                      <NavLink to={`./user/promotion-upload/`}>
                        <Button
                          type="primary"
                          width="96px"
                          className="button-primary"
                          icon={<UploadOutlined />}
                        >
                          Upload Promote File
                        </Button>
                      </NavLink>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Alert
              message={`A promotional email will be sent, and employee profile will be updated on the 26th of each month at 9:00 a.m. `}
              type="info"
              className="custom-info"
              banner
            />
          </Col>
          <Col span={24}>
            <Table
              columns={columns}
              scroll={{
                x: 'max-content',
                y:
                  userState.currentRoleId === 2
                    ? 'calc(100vh - 220px)'
                    : 'calc(100vh - 340px)',
              }}
              dataSource={data}
              rowKey="id"
              size="small"
              loading={isLoading}
              pagination={{
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '30', '40'],
                locale: { items_per_page: '/ page' },
                position: ['bottomRight'],
                defaultPageSize: 20,
              }}
            />
          </Col>
        </Row>
      </Layout>
    </div>
  );
};

export default PromoteViewPage;
