import { message } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { FlagEnum } from './../../services/flagEnum.js';
import { AppContext } from './AppContext';
import { RoleService } from '../../services/api';

export const FeatureFlags = React.createContext();

export const FeatureFlagsProvider = ({ children }) => {
  const [features, setFeatures] = useState();
  const { userState } = useContext(AppContext);

  const mapFlagToEnum = (allActions) => {
    const result = {};
    allActions.forEach((actions) => {
      if (actions.actions) {
        actions.actions.forEach((action) => {
          for (const key in FlagEnum) {
            if (FlagEnum[key] === action.code) {
              result[key] = action.isActived;
            }
          }
        });
      }
      if (actions.primaryActions) {
        actions.primaryActions.forEach((action) => {
          for (const key in FlagEnum) {
            if (FlagEnum[key] === action.code) {
              result[key] = action.isActived;
            }
          }
        });
      }
    });
    return result;
  };

  useEffect(() => {
    try {
      RoleService.getRolePermissionById(
        userState.currentRoleId,
        ({ data }) => {
          const mappedFlag = mapFlagToEnum(data.allActions);
          setFeatures(mappedFlag);
        },
        (response) => {
          message.error(
            "Can't fetch role permission - " + response.data.message,
          );
        },
      );
    } catch (err) {
      message.error("Can't fetch role permission.");
    }
  }, [userState]);

  return (
    <FeatureFlags.Provider value={{ features }}>
      {children}
    </FeatureFlags.Provider>
  );
};
