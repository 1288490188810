import {
  Row,
  Col,
  message,
  Button,
  Form,
  Layout,
  Upload,
  Tooltip,
  Alert,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  GeneralInfoService,
  MasterDataService,
  PromotionHistoryService,
  UserInfoService,
} from '../../services/api';
import promotionTemplate from '../../assets/files/promotionTemplate.xlsx';

import {
  DeleteOutlined,
  DownloadOutlined,
  ExclamationCircleFilled,
  FileTextOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { Text, Table } from '../../components';
import FormInputNew from '../../components/FormInputNew';
import { useForm } from 'antd/lib/form/Form';
import { useHistory } from 'react-router-dom';
import UploadIcon from '../../assets/icons/uploadIcon.svg';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import Search from 'antd/lib/input/Search';
import { filterBySearchValue } from '../../services/search';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const { Content } = Layout;

const PromotionUploadPage = () => {
  const [form] = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [promotionHistoryFile, setPromotionHistoryFile] = useState(null);
  const [mainPromotionHistoryData, setMainPromotionHistoryData] = useState([]);
  const [promotionHistoryData, setPromotionHistoryData] = useState([]);
  const [positionList, setPositionList] = useState();
  const [departmentList, setDepartmentList] = useState();
  const [isCorrect, setCorrect] = useState(false);
  const [countError, setCountError] = useState(0);
  const history = useHistory();

  const fileSizeLimit = 5 * 1024 * 1024;

  useEffect(() => {
    form.setFieldsValue({
      promotionHistoryFile: promotionHistoryFile,
    });
  }, [promotionHistoryFile]);

  useEffect(() => {
    MasterDataService.fetchDepartmentList({}, async ({ data }) => {
      data = data.filter((value) => {
        return value.isActived;
      });
      setDepartmentList(data);
    });
    MasterDataService.fetchPositionList({}, async ({ data }) => {
      data = data.filter((value) => {
        return value.isActived;
      });
      setPositionList(data);
    });
  }, []);

  const toCamelCase = (str) => {
    return str
      .replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => chr.toUpperCase())
      .replace(/^[A-Z]/, (chr) => chr.toLowerCase());
  };

  const defaultStringCompare = (property) => (a, b) =>
    String(a[property]).localeCompare(String(b[property]));

  const defaultNumberCompare = (property) => (a, b) =>
    Number(a[property]) - Number(b[property]);

  const keyMapping = {
    'employeeNo.': 'empCode',
    'invoiceReceiptNo.': 'invoiceReceiptNo',
  };

  const fieldMapping = {
    empCode: 'Emp No.',
    treatmentDate: 'Treatment Date',
    amount: 'Amount',
    invoiceReceiptNo: 'Invoice/Receipt No.',
    hospitalReason: 'Hospital and Reason',
  };

  const downloadExcelTemplate = () => {
    const link = document.createElement('a');
    link.href = promotionTemplate;
    link.download = 'promotionTemplate.xlsx';
    link.click();
  };

  const columns = [
    {
      title: 'Emp No.',
      dataIndex: 'empCode',
      width: '80px',
      fixed: 'left',
      sorter: (a, b) => a.empCode - b.empCode,
      render: (value, record) => {
        let tooltipTitle = null;

        if (record.empCode && !record.isUser) {
          tooltipTitle = `Employee No. ${record.empCode} not found.`;
        } else if (record.nullField && record.nullField.length > 0) {
          tooltipTitle = `${record.nullField
            .filter((data) => fieldMapping[data])
            .map((data) => fieldMapping[data])
            .join(', ')} ${
            record.nullField.length > 1 ? 'fields are' : 'field is'
          } empty. All fields are required.`;
        } else if (record.isDuplicate) {
          tooltipTitle = 'Duplicate Employee No.';
        } else if (!record.isPromote) {
          if (record.newLevel && !record.newPosition && !record.newDepartment) {
            tooltipTitle = 'The new promotion level must exceed the old level.';
          } else if (
            record.newLevel &&
            !record.newPosition &&
            record.newDepartment
          ) {
            tooltipTitle = 'The new promotion level must exceed the old level.';
          } else if (
            !record.newLevel &&
            record.newPosition &&
            record.newDepartment
          ) {
            tooltipTitle = 'Position and department should updated together.';
          } else if (
            record.newLevel &&
            record.newPosition &&
            record.newDepartment
          ) {
            tooltipTitle = 'Level, position, and department should updated.';
          } else if (
            record.newLevel &&
            record.newPosition &&
            !record.newDepartment
          ) {
            tooltipTitle = 'Position and level should updated.';
          } else if (
            !record.newLevel &&
            record.newPosition &&
            !record.newDepartment
          ) {
            tooltipTitle = 'Position should updated.';
          } else if (
            !record.newLevel &&
            !record.newPosition &&
            !record.newDepartment
          ) {
            tooltipTitle = `Employee No. ${record.empCode} hasn't been promoted.`;
          } else if (
            !record.newLevel &&
            !record.newPosition &&
            record.newDepartment
          ) {
            tooltipTitle =
              'If department changes, then position should update accordingly.';
          }
        } else if (!record.isPositionInDb || !record.isDepartmentInDb) {
          if (!record.isPositionInDb && !record.isDepartmentInDb) {
            tooltipTitle =
              'Position and department are not available in the system.';
          } else if (!record.isPositionInDb) {
            tooltipTitle = 'This position is not available in the system.';
          } else if (!record.isDepartmentInDb) {
            tooltipTitle = 'This department is not available in the system.';
          }
        }

        return (
          <span>
            <Text
              small12
              className="text-normal"
              style={{
                color:
                  !record.isUser ||
                  record.isDuplicate ||
                  !record.isPromote ||
                  !record.isPositionInDb ||
                  !record.isDepartmentInDb ||
                  (record.nullField && record.nullField.length > 0)
                    ? 'red'
                    : null,
              }}
            >
              {record.empCode}
            </Text>
            {tooltipTitle ? (
              <Tooltip title={tooltipTitle}>
                <ExclamationCircleFilled
                  style={{ color: 'red', fontSize: '12px', marginLeft: '8px' }}
                />
              </Tooltip>
            ) : null}
          </span>
        );
      },
    },
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      width: '150px',
      fixed: 'left',
      sorter: defaultStringCompare('firstnameTh'),
      render: (value, record) => (
        <Text small12 className="text-normal">
          {record.firstnameTh && record.lastnameTh
            ? `${record.firstnameTh} ${record.lastnameTh}`
            : null}
        </Text>
      ),
    },
    {
      title: 'Type',
      key: 'type',
      fixed: 'left',
      width: '100px',
      sorter: defaultStringCompare('type'),
      render: (_, record) => (
        <Text small12 capitalize className="text-normal">
          {record.newLevel && record.newPosition
            ? `เลื่อนขั้นและเปลี่ยนตำแหน่ง`
            : record.newLevel
            ? `เลื่อนขั้น`
            : record.newPosition
            ? `เปลี่ยนตำแหน่ง`
            : `-`}
        </Text>
      ),
    },
    {
      title: 'New Level',
      key: 'newlevel',
      dataIndex: 'newLevel',
      width: '100px',
      align: 'center',
      sorter: defaultStringCompare('newLevel'),
      render: (value, record) => (
        <Text small12 capitalize className="text-normal">
          {value ? (
            <a
              style={{
                color:
                  !record.isPromote && parseInt(value) <= parseInt(record.level)
                    ? 'red'
                    : null,
              }}
            >
              {value}
            </a>
          ) : (
            '-'
          )}
        </Text>
      ),
    },
    {
      title: 'New Position',
      key: 'newPosition',
      dataIndex: 'newPosition',
      width: '200px',
      align: 'center',
      sorter: defaultStringCompare('newPosition'),
      render: (value, record) => (
        <Text small12 capitalize className="text-normal">
          {value ? (
            <a
              style={{
                color:
                  (!record.isPromote && value === record.position) ||
                  !record.isPositionInDb
                    ? 'red'
                    : null,
              }}
            >
              {value}
            </a>
          ) : (
            '-'
          )}
        </Text>
      ),
    },
    {
      title: 'New Department',
      key: 'newDepartment',
      dataIndex: 'newDepartment',
      width: '200px',
      align: 'center',
      sorter: defaultStringCompare('newDepartment'),
      render: (value, record) => (
        <Text small12 capitalize className="text-normal">
          {value ? (
            <a
              style={{
                color:
                  (!record.isPromote && value === record.department) ||
                  !record.isDepartmentInDb
                    ? 'red'
                    : null,
              }}
            >
              {value}
            </a>
          ) : (
            '-'
          )}
        </Text>
      ),
    },
    {
      title: 'Level',
      key: 'level',
      dataIndex: 'level',
      width: '100px',
      align: 'center',
      sorter: defaultStringCompare('level'),
      render: (value) => (
        <Text small12 capitalize className="text-normal">
          {value}
        </Text>
      ),
    },
    {
      title: 'Position',
      key: 'position',
      dataIndex: 'position',
      width: '200px',
      align: 'center',
      sorter: defaultStringCompare('position'),
      render: (value) => (
        <Text small12 capitalize className="text-normal">
          {value}
        </Text>
      ),
    },
    {
      title: 'Department',
      key: 'department',
      dataIndex: 'department',
      width: '200px',
      align: 'center',
      sorter: defaultStringCompare('department'),
      render: (value) => (
        <Text small12 capitalize className="text-normal">
          {value}
        </Text>
      ),
    },
  ];

  const fetchGeneralInfo = (ids, uploadedData) => {
    return new Promise((resolve, reject) => {
      GeneralInfoService.fetchGeneralInfoList(
        {
          empCode: ids,
        },
        ({ data: fetchedData }) => {
          const updatedData = uploadedData.map((item) => {
            const userInfo = fetchedData.find(
              (info) => info.empCode === item.empCode,
            );
            return userInfo
              ? {
                  ...item,
                  firstnameTh: userInfo.firstnameTh,
                  lastnameTh: userInfo.lastnameTh,
                  nicknameEn: userInfo.nicknameEn,
                  email: userInfo.email,
                  userId: userInfo.userId,
                  position: userInfo.positionToPositionId?.positionName,
                  department: userInfo.departmentToDepartmentId?.name,
                  level: userInfo.levelToLevelNo?.levelNo,
                }
              : item;
          });
          resolve(updatedData);
        },
        (response) => {
          if (response) {
            message.error(`Error: ${response.data.message}`);
            reject(response);
          }
        },
      );
    });
  };

  const validateFileType = (file) => {
    const allowedTypes = ['.csv', '.xlsx'];
    const isValid = allowedTypes.some((type) => file.name.endsWith(type));

    if (!isValid) {
      message.error('Failed to upload file: Incorrect file format.');
    }

    return isValid || Upload.LIST_IGNORE;
  };

  const handleFileUpload = ({ file }) => {
    setIsLoading(true);

    if (file.size > fileSizeLimit) {
      message.error(
        `${file.name} is larger than ${
          fileSizeLimit / (1024 * 1024)
        } MB. Please select a smaller file.`,
      );
      setIsLoading(false);
      return;
    }

    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = e.target.result;
      const isCSV = file.name.endsWith('.csv');
      const workbook = XLSX.read(data, {
        type: isCSV ? 'string' : 'binary',
      });

      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      let jsonData = XLSX.utils.sheet_to_json(sheet, { raw: false });

      if (jsonData.length <= 0) {
        setCorrect(false);
        message.error('Failed to upload file: Incorrect file format.');
        return;
      }

      const empCodes = [];
      const empCount = {};

      const requiredFields = ['empCode'];

      jsonData.forEach((row) => {
        const empNo = row['Employee ID'];
        empCount[empNo] = (empCount[empNo] || 0) + 1;
        if (!empCodes.includes(empNo)) {
          empCodes.push(empNo);
        }
      });

      jsonData = jsonData.map((row) => {
        const newRow = {};
        const empNo = row['Employee ID'];
        const nullFields = [];

        Object.keys(row).forEach((key) => {
          const camelKey = toCamelCase(key);
          const finalKey = keyMapping[camelKey] || camelKey;

          if (finalKey === 'employeeID') {
            newRow['empCode'] = row[key];
          } else {
            newRow[finalKey] = row[key];
          }
        });

        newRow.isDuplicate = empCount[empNo] > 1;

        requiredFields.forEach((field) => {
          if (!(field in newRow)) {
            newRow[field] = null;
            nullFields.push(field);
          }
        });

        newRow.nullField = nullFields;

        return newRow;
      });

      try {
        jsonData = await fetchGeneralInfo(empCodes, jsonData);

        const finalData = jsonData.map((row) => {
          row.isUser = Boolean(row.firstnameTh && row.lastnameTh && row.userId);
          const nullFields = requiredFields.filter(
            (field) => row[field] === null || row[field] === undefined,
          );

          row.isDepartmentInDb =
            !row.newDepartment ||
            (Array.isArray(departmentList) &&
              departmentList.some((d) => d.name === row.newDepartment));

          row.isPositionInDb =
            !row.newPosition ||
            (Array.isArray(positionList) &&
              positionList.some((p) => p.positionName === row.newPosition));

          row.nullField = nullFields;
          row.isPromote = false;

          //New Level
          if (row.newLevel && !row.newDepartment && !row.newPosition) {
            row.isPromote = parseInt(row.newLevel) > parseInt(row.level);
          }

          //New Department
          if (!row.newLevel && row.newDepartment && row.newPosition) {
            row.isPromote =
              row.newDepartment !== row.department &&
              row.newPosition !== row.position;
          }

          //New Position
          if (!row.newLevel && !row.newDepartment && row.newPosition) {
            row.isPromote = row.newPosition !== row.position;
          }

          //New Level and Position
          if (row.newLevel && !row.newDepartment && row.newPosition) {
            row.isPromote =
              row.newLevel > row.level && row.newPosition !== row.position;
          }

          //New Level, Position and Department
          if (row.newLevel && row.newDepartment && row.newPosition) {
            row.isPromote =
              row.newDepartment !== row.department &&
              row.newLevel > row.level &&
              row.newPosition !== row.position;
          }

          //No promoted
          if (!row.newLevel && !row.newDepartment && !row.newPosition) {
            row.isPromote = false;
          }

          return row;
        });

        setCountError(
          finalData.reduce((count, data) => {
            if (
              !data.isUser ||
              data.isDuplicate ||
              !data.isPromote ||
              !data.isPositionInDb ||
              !data.isDepartmentInDb
            ) {
              count += 1;
            }
            return count;
          }, 0),
        );

        const isIncorrect = finalData.some(
          (data) =>
            !data.isUser ||
            data.isDuplicate ||
            !data.isPromote ||
            !data.isPositionInDb ||
            !data.isDepartmentInDb,
        );

        setCorrect(!isIncorrect);

        setMainPromotionHistoryData(finalData);
        setPromotionHistoryData(finalData);
      } catch (error) {
        console.error('Error processing uploaded data:', error);
      }
    };

    reader.onerror = (error) => {
      console.error('Error reading file:', error);
    };

    if (file.type === 'text/csv') {
      reader.readAsText(file, 'UTF-8');
    } else {
      reader.readAsBinaryString(file);
    }

    setPromotionHistoryFile({
      fileName: 'promotionHistoryFile',
      file,
    });

    setIsLoading(false);
  };

  const onSearch = (value) => {
    setPromotionHistoryData(
      filterBySearchValue(mainPromotionHistoryData || [], value, [
        'empCode',
        'fullnameTh',
        'nicknameEn',
        'email',
      ]),
    );
  };

  const handleFileDelete = () => {
    setPromotionHistoryFile(null);
    setPromotionHistoryData([]);
    setMainPromotionHistoryData([]);
    setCountError(0);
    setCorrect(false);
  };

  const onFinishPromotionHistoryUpload = (value) => {
    const payload = mainPromotionHistoryData.map((item) => ({
      empCode: item.empCode,
      department: item.newDepartment,
      position: item.newPosition,
      level: item.newLevel,
    }));
    setIsLoading(true);

    PromotionHistoryService.addPromotionHistory(
      payload,
      ({ data }) => {
        setIsLoading(false);
        message.success(
          'Promotion history upload has been created successfully.',
        );
        setTimeout(() => {
          history.replace('/user', { tabKey: '2' });
        }, 1000);
      },
      (response) => {
        setIsLoading(false);
        if (response) {
          message.error(
            `Failed to upload promotion history request: ${response.data.message}`,
          );
        }
      },
    );
  };

  return (
    <div style={{ width: 'auto' }}>
      <Layout style={{ minHeight: 'calc(100vh - 64px)' }}>
        <Content
          style={{
            margin: '32px 20px 20px 20px',
            overflow: 'initial',
          }}
        >
          <Form
            style={{ width: '100%' }}
            form={form}
            onFinish={onFinishPromotionHistoryUpload}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Row
                  gutter={[16, 16]}
                  align="middle"
                  style={{ height: '100%' }}
                  wrap={false}
                >
                  <Col flex>
                    <NavLink
                      to={{ pathname: '/user', state: { tabKey: '2' } }}
                      form="user-form"
                    >
                      <LeftOutlined className="text-normal" />
                    </NavLink>
                  </Col>
                  <Col
                    flex
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <Text h4 className="text-normal">
                      Upload Promotion File
                    </Text>
                  </Col>
                  <Col flex="auto" style={{ textAlign: 'right' }}>
                    <Button
                      width="96px"
                      className="button-outlined"
                      onClick={downloadExcelTemplate}
                      icon={<DownloadOutlined />}
                    >
                      Download Template
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row className="card-container" gutter={[16, 16]}>
                  <Col span={24}>
                    <Text sub2 className="text-normal">
                      Upload File
                    </Text>
                  </Col>
                  <Col span={24}>
                    <FormInputNew
                      title="Select File to Upload"
                      required
                      component={
                        <Form.Item
                          style={{ width: '100%', margin: '0' }}
                          name="medicalClaim"
                          rules={[
                            {
                              required: true,
                              message: 'Please upload medical claim',
                            },
                          ]}
                          extra={
                            <span
                              style={{ fontSize: '12px', color: '#52575C' }}
                            >
                              Please upload a .xlsx file not exceeding 5MB.
                            </span>
                          }
                        >
                          {!promotionHistoryFile ? (
                            <Col xs={24} md={12}>
                              <Upload
                                showUploadList={false}
                                accept={'.xlsx'}
                                customRequest={handleFileUpload}
                                beforeUpload={validateFileType}
                              >
                                <Button
                                  type="link"
                                  icon={
                                    <img
                                      src={UploadIcon}
                                      width={16}
                                      height={16}
                                    />
                                  }
                                  style={{
                                    display: 'flex',
                                    float: 'left',
                                    padding: '0',
                                    gap: '8px',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Text sub3 className="text-normal">
                                    Upload File
                                  </Text>
                                </Button>
                              </Upload>
                            </Col>
                          ) : (
                            <Col xs={24} md={12}>
                              <Row className="card-container-bg-grey">
                                <Col
                                  xs={2}
                                  md={1}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                  }}
                                >
                                  <FileTextOutlined />
                                </Col>

                                <Col
                                  xs={20}
                                  md={22}
                                  style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  <Text body14 className="text-secondary-blue">
                                    {promotionHistoryFile.file.name}
                                  </Text>
                                </Col>

                                <Col
                                  xs={2}
                                  md={1}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'end',
                                  }}
                                >
                                  <DeleteOutlined
                                    onClick={handleFileDelete}
                                    className="text-secondary-red"
                                  />
                                </Col>
                              </Row>
                            </Col>
                          )}
                        </Form.Item>
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row className="card-container" gutter={[16, 16]}>
                  <Col span={24}>
                    <Text sub2 className="text-normal">
                      Preview Data
                    </Text>
                  </Col>
                  {countError > 0 && !isCorrect && (
                    <Col span={24}>
                      <Alert
                        message={`An error occurred in ${countError} ${
                          countError > 1 ? 'cases' : 'case'
                        }. Please review the data, correct the issues, and upload it again.`}
                        type="warning"
                        style={{
                          backgroundColor: '#F4364C14',
                        }}
                        className="custom-alert"
                        banner
                      />
                    </Col>
                  )}
                  <Col
                    flex="auto"
                    style={{
                      maxWidth: 320,
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Search
                      placeholder="Search"
                      onSearch={(value, event) => {
                        if (event) {
                          event.preventDefault();
                        }
                        onSearch(value);
                      }}
                      allowClear={true}
                      style={{ width: '100%', maxWidth: 320 }}
                    />
                  </Col>
                  <Col span={24}>
                    <Table
                      columns={columns}
                      scroll={{
                        x: 500,
                        y: 'calc(100vh - 260px)',
                      }}
                      dataSource={promotionHistoryData}
                      rowKey="id"
                      size="small"
                      loading={isLoading}
                      pagination={{
                        position: ['bottomRight'],
                        pageSizeOptions: [10, 20, 30, 40],
                        showSizeChanger: true,
                        defaultPageSize: 20,
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[8, 8]} justify="end" align="middle">
                  <Col flex>
                    <NavLink
                      to={{ pathname: '/user', state: { tabKey: '2' } }}
                      form="user-form"
                    >
                      <Button width="96px" className="button-outlined">
                        Cancel
                      </Button>
                    </NavLink>
                  </Col>
                  <Col flex>
                    <Button
                      type="primary"
                      width="96px"
                      className="button-primary"
                      loading={isLoading}
                      onClick={() => {
                        form.submit();
                      }}
                      disabled={!isCorrect}
                    >
                      Upload
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Content>
      </Layout>
    </div>
  );
};

export default PromotionUploadPage;
