import React, { Component, useContext, useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  AutoComplete,
  Typography,
  Spin,
  message,
  Form,
  Layout,
} from 'antd';
import {
  ProfileService,
  UserService,
  ReportService,
  TimesheetReportService,
} from '../services/api';
import moment from 'moment';

import { Text, Select, FormInput, Input } from '../components';

import { DownloadOutlined } from '@ant-design/icons';
import { AppContext } from '../components/context/AppContext';
import { FeatureFlags } from '../components/context/FeatureFlagContext';
import { useForm } from 'antd/lib/form/Form';

var jwt = require('jsonwebtoken');
var token = sessionStorage.getItem('access-token');
var decode1 = jwt.decode(token);
const userId = decode1?.userId;

const { Content } = Layout;

const Report = () => {
  const [form] = useForm();

  const { features } = useContext(FeatureFlags);
  const { userState } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(true);
  const [timesheetType, setTimesheetType] = useState('Normal');
  const [year, setYear] = useState(moment().year());
  const [month, setMonth] = useState(moment().month() + 1);
  const [projectOptions, setProjectOptions] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [userDetail, setUserDetail] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [selectedUserDetail, setSelectedUserDetail] = useState();
  const [optionsList, setOptionsList] = useState();
  const [optionsData, setOptionsData] = useState();
  const [monthList, setMonthList] = useState([
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]);

  useEffect(() => {
    setSelectedUserDetail(null);
    setTimesheetType('Normal');
    form.resetFields();
    getPersonalDetail();
    UserService.getUserOptions(
      {
        isActived: true,
      },
      ({ data }) => {
        setOptionsData(data);
      },
      (error) => {
        if (error) message.error(`Error: ${error.data.message}`);
      },
    );
  }, [userState]);

  useEffect(() => {
    handleFetchProject();
  }, [userState, userDetail, selectedUserDetail, year, month]);

  const getPersonalDetail = async () => {
    await ProfileService.personalDetail.getPersonalDetail(
      userId,
      async ({ data }) => {
        setUserDetail(data);
        if (userState.currentRoleId === 2) {
          form.setFieldValue(
            'userId',
            data?.firstnameEn + ' ' + data?.lastnameEn,
          );
        }
      },
      (response) => {
        if (response)
          message.error(
            `Failed to fetch user detail: ${response.data.message}`,
          );
      },
    );
    setIsLoading(false);
  };

  const getOptions = (search) => {
    let isSearchThai = search && /[\u0E00-\u0E7F]/g.test(search);
    const emailList = optionsData
      .filter(
        (optionData) =>
          (optionData.email &&
            optionData.email.toLowerCase().includes(search.toLowerCase())) ||
          (optionData.firstnameEn &&
            optionData.firstnameEn
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (optionData.lastnameEn &&
            optionData.lastnameEn
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (optionData.nicknameEn &&
            optionData.nicknameEn
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (optionData.firstnameTh && optionData.firstnameTh.includes(search)) ||
          (optionData.lastnameTh && optionData.lastnameTh.includes(search)) ||
          (optionData.nicknameTh && optionData.nicknameTh.includes(search)),
      )
      .map((res) => {
        const getHighlightedText = (text) => {
          if (text !== null) {
            let searchCriteria = text;
            let index = searchCriteria
              .toLowerCase()
              .indexOf(search.toLowerCase());

            if (search.length != 0 && index !== -1) {
              let prefix = searchCriteria.substr(0, index);
              let subfix = searchCriteria.substr(index + search.length);
              let matched = searchCriteria.substr(index, search.length);
              return (
                <>
                  {prefix}
                  <span style={{ fontWeight: 'bold', fontColor: '#004368' }}>
                    {matched}
                  </span>
                  {subfix}
                </>
              );
            } else if (
              !isSearchThai &&
              (res.firstnameEn
                .toLowerCase()
                .indexOf(search.toLowerCase().trim()) !== -1 ||
                res.lastnameEn
                  .toLowerCase()
                  .indexOf(search.toLowerCase().trim()) !== -1 ||
                res.email.toLowerCase().indexOf(search.toLowerCase().trim()) !==
                  -1 ||
                res.nicknameEn
                  .toLowerCase()
                  .indexOf(search.toLowerCase().trim()) !== -1)
            ) {
              return (
                [
                  res.firstnameEn,
                  res.lastnameEn,
                  res.nicknameEn,
                  res.email,
                ].includes(text) && text
              );
            } else {
              return (
                [
                  res.firstnameTh,
                  res.lastnameTh,
                  res.nicknameTh,
                  res.email,
                ].includes(text) && text
              );
            }
          }
        };

        return {
          email: res.email,
          value: `${res.firstnameEn} ${res.lastnameEn} (${res.email})`,
          label: (
            <Row>
              <Col span={15}>
                <Typography.Title
                  style={{
                    fontWeight: 'normal',
                    fontSize: '15px',
                  }}
                  level={5}
                  ellipsis={{
                    rows: 1,
                  }}
                >
                  {getHighlightedText(res.firstnameEn)}
                  {getHighlightedText(res.firstnameTh)}{' '}
                  {getHighlightedText(res.lastnameEn)}
                  {getHighlightedText(res.lastnameTh)}
                </Typography.Title>

                <Typography.Text
                  style={{
                    fontWeight: 'normal',
                    fontSize: '12px',
                  }}
                  ellipsis={{
                    rows: 1,
                  }}
                >
                  {getHighlightedText(res.email)}
                </Typography.Text>
              </Col>
              <Col span={5} offset={3}>
                <Typography.Title
                  style={{
                    fontWeight: 'normal',
                    fontSize: '15px',
                    textAlign: 'left',
                  }}
                  level={5}
                  ellipsis={{
                    rows: 1,
                  }}
                >
                  {getHighlightedText(res.nicknameEn)}
                  {getHighlightedText(res.nicknameTh)}
                </Typography.Title>
              </Col>
            </Row>
          ),
        };
      });
    setOptionsList(emailList);
  };

  const selectOptions = async (value, option) => {
    form.setFieldsValue({
      month: moment().month() + 1,
      year: moment().year(),
      projectId: null,
    });
    const selectedUser = optionsData.find(
      (optionData) => optionData.email === option.email,
    );
    setSelectedUser(selectedUser);

    await ProfileService.personalDetail.getPersonalDetail(
      selectedUser.userId,
      async ({ data }) => {
        const dataValue = JSON.stringify(data);
        const setDataValue = JSON.parse(dataValue);

        setSelectedUserDetail(setDataValue);
      },
      (response) => {
        if (response)
          message.error(
            `Failed to fetch personal detail: ${response.data.message}`,
          );
      },
    );
    await ReportService.fetchUserProjectByMonth(
      {
        userId: selectedUser.userId,
        year: year,
        month: month,
      },
      (data) => {
        if (data) {
          setProjectOptions(data.data.projectOptions);
        }
      },
      (response) => {
        if (response)
          message.error(
            `Failed to fetch user project by month: ${response.data.message}`,
          );
      },
    );
  };

  const getYearList = () => {
    let startDate;

    if (userState.currentRoleId !== 2) {
      startDate = selectedUserDetail?.startJobDate;
    } else if (userState.currentRoleId === 2) {
      startDate = userDetail?.startJobDate;
    } else return;

    let startYear = moment(startDate).year();
    let currYear = moment().year();

    let res = [];
    for (let i = currYear; i >= startYear; i--) {
      res.push(<Select.Option value={i}>{i}</Select.Option>);
    }

    return res;
  };

  const getYearListSummary = () => {
    let res = [];
    for (let i = moment().year(); i >= 2017; i--) {
      res.push(<Select.Option value={i}>{i}</Select.Option>);
    }

    return res;
  };

  const getMonthList = () => {
    let startDate;
    if (!selectedUserDetail) {
      let res = [];
      for (let i = month; i > 0; i--) {
        res.push(<Select.Option value={i}>{monthList[i - 1]}</Select.Option>);
      }
      return res;
    } else {
      startDate =
        userState.currentRoleId === 2
          ? userDetail?.startJobDate
          : selectedUserDetail?.startJobDate;

      let startYear = moment(startDate).year();
      let startMonth = moment(startDate).month() + 1;

      let currYear = moment().year();
      let currMonth = moment().month() + 1;

      let res = [];

      if (startYear == currYear && startMonth == currMonth) {
        res.push(
          <Select.Option value={currMonth}>
            {monthList[currMonth - 1]}
          </Select.Option>,
        );
      } else if (year == currYear) {
        if (currYear == startYear) {
          for (let i = currMonth; i >= startMonth; i--) {
            res.push(
              <Select.Option value={i}>{monthList[i - 1]}</Select.Option>,
            );
          }
        } else {
          for (let i = currMonth; i > 0; i--) {
            res.push(
              <Select.Option value={i}>{monthList[i - 1]}</Select.Option>,
            );
          }
        }
      } else if (year === startYear) {
        for (let i = 12; i >= startMonth; i--) {
          res.push(<Select.Option value={i}>{monthList[i - 1]}</Select.Option>);
        }
      } else {
        for (let i = 12; i > 0; i--) {
          res.push(<Select.Option value={i}>{monthList[i - 1]}</Select.Option>);
        }
      }
      return res;
    }
  };

  const handleFetchProject = async () => {
    await ReportService.fetchUserProjectByMonth(
      {
        userId:
          userState.currentRoleId === 2 ? userId : selectedUserDetail?.userId,
        year: year,
        month: month,
      },
      (data) => {
        if (data) {
          setProjectOptions(data.data.projectOptions);
        }
      },
      (response) => {
        if (response) message.error(`Error: ${response.data.message}`);
      },
    );
  };

  const getProject = () => {
    let res = [];
    if (projectOptions) {
      projectOptions.forEach((value) => {
        res.push(
          <Select.Option value={value.projectId}>
            {value.projectNo + ' - ' + value.projectName}
          </Select.Option>,
        );
      });
    }
    return res;
  };

  const handleSubmit = (values) => {
    try {
      if (
        values.timesheetType === 'Normal' ||
        values.timesheetType === 'Special'
      ) {
        ReportService.fetchTimesheetReportByMonth(
          {
            userId:
              userState.currentRoleId === 2
                ? userDetail.userId
                : selectedUserDetail.userId,
            year: values.year,
            month: values.month,
            projectId: values.projectId,
            type: values.timesheetType,
          },
          (response) => {
            if (response) message.error(`Error: ${response.data.message}`);
          },
        );
      } else if (values.timesheetType === 'Summary') {
        ReportService.fetchSummaryTimesheetReportByYear(
          {
            year: values.year,
          },
          (response) => {
            if (response) message.error(`Error: ${response.data.message}`);
          },
        );
      } else if (values.timesheetType === 'SummaryOt') {
        ReportService.fetchSummaryOTTimesheetReportByMonth(
          {
            month: values.month,
            year: values.year,
          },
          (response) => {
            if (response) message.error(`Error: ${response.data.message}`);
          },
        );
      } else if (values.timesheetType === 'SummaryByProject') {
        ReportService.fetchSummaryTimesheetReportByProject(
          {
            year: values.year,
          },
          (response) => {
            if (response) message.error(`Error: ${response.data.message}`);
          },
        );
      }
    } catch (err) {
      message.error(err);
    } finally {
    }
  };

  return (
    <div style={{ width: 'auto' }}>
      <Spin spinning={isLoading}>
        <Layout style={{ minHeight: 'calc(100vh - 64px)' }}>
          <Content
            style={{
              margin: '32px 20px 0 20px',
              overflow: 'initial',
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Row gutter={[16, 16]}>
                  <Col flex>
                    <Text h4 className="text-normal">
                      Report
                    </Text>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Form
                  form={form}
                  onFinish={handleSubmit}
                  requiredMark={true}
                  initialValues={{
                    timesheetType: 'Normal',
                    month: moment().month() + 1,
                    year: moment().year(),
                  }}
                >
                  <Row className="card-container" gutter={[16, 16]}>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                      <FormInput
                        title="Report Type"
                        component={
                          <Form.Item name="timesheetType" style={{ margin: 0 }}>
                            <Select
                              onChange={(value) => {
                                setTimesheetType(value);
                                setYear(moment().year());
                                setMonth(moment().month() + 1);
                                if (userState.currentRoleId !== 2) {
                                  setSelectedUserDetail(null);
                                  form.resetFields();
                                }
                                form.setFieldsValue({
                                  timesheetType: value,
                                  year: moment().year(),
                                  month: moment().month() + 1,
                                });
                              }}
                            >
                              <Select.Option value="Normal">
                                Timesheet (Normal)
                              </Select.Option>
                              <Select.Option value="Special">
                                Timesheet (Special)
                              </Select.Option>
                              {userState.currentRoleId !== 2 && (
                                <>
                                  <Select.Option value="Summary">
                                    Summary Timesheet
                                  </Select.Option>
                                  <Select.Option value="SummaryOt">
                                    Summary Timesheet (OT)
                                  </Select.Option>
                                  <Select.Option value="SummaryByProject">
                                    Summary Timesheet By Project
                                  </Select.Option>
                                </>
                              )}
                            </Select>
                          </Form.Item>
                        }
                      />
                    </Col>
                    {userState.currentRoleId !== 2 && (
                      <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                        <FormInput
                          title="User"
                          component={
                            <Form.Item
                              style={{ margin: 0 }}
                              name="userId"
                              rules={[
                                {
                                  required: !timesheetType.includes('Summary'),
                                  message: 'Please select user',
                                },
                              ]}
                            >
                              <AutoComplete
                                placeholder="Search User"
                                onChange={getOptions}
                                options={optionsList}
                                onSelect={selectOptions}
                                disabled={timesheetType.includes('Summary')}
                              />
                            </Form.Item>
                          }
                        />
                      </Col>
                    )}
                    {userState.currentRoleId === 2 && (
                      <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                        <FormInput
                          title="User"
                          component={
                            <Form.Item name="userId" style={{ margin: 0 }}>
                              <Input
                                value={
                                  userDetail?.firstnameEn +
                                  ' ' +
                                  userDetail?.lastnameEn
                                }
                                disabled
                              />
                            </Form.Item>
                          }
                        />
                      </Col>
                    )}
                    <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                      <FormInput
                        title="Year"
                        component={
                          <Form.Item name="year" style={{ margin: 0 }}>
                            <Select
                              onChange={(value) => {
                                if (value != moment().year()) {
                                  setMonth(12);
                                  form.setFieldsValue({
                                    month: 12,
                                    projectId: null,
                                  });
                                } else {
                                  setMonth(moment().month() + 1);
                                  form.setFieldsValue({
                                    month: moment().month() + 1,
                                    projectId: null,
                                  });
                                }
                                setYear(value);
                              }}
                            >
                              {!timesheetType.includes('Summary')
                                ? getYearList()
                                : getYearListSummary()}
                            </Select>
                          </Form.Item>
                        }
                      />
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                      <FormInput
                        title="Month"
                        component={
                          <Form.Item name="month" style={{ margin: 0 }}>
                            <Select
                              onChange={(value) => {
                                setMonth(value);
                                form.setFieldsValue({
                                  projectId: null,
                                });
                              }}
                              disabled={
                                timesheetType === 'Summary' ||
                                timesheetType === 'SummaryByProject' ||
                                (!form.getFieldValue('userId') &&
                                  timesheetType !== 'SummaryOt')
                              }
                            >
                              {getMonthList()}
                            </Select>
                          </Form.Item>
                        }
                      />
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                      <FormInput
                        title="Project"
                        component={
                          <Form.Item
                            name="projectId"
                            style={{ margin: 0 }}
                            rules={[
                              {
                                required: !timesheetType.includes('Summary'),
                                message: 'Please select project',
                              },
                            ]}
                          >
                            <Select
                              placeholder="Search Project"
                              disabled={
                                timesheetType.includes('Summary') ||
                                !form.getFieldValue('userId')
                              }
                              onChange={(value) => {
                                setSelectedProject(value);
                              }}
                            >
                              {getProject()}
                            </Select>
                          </Form.Item>
                        }
                      />
                    </Col>
                    <Col span={24}>
                      <Row justify="end">
                        <Col>
                          <Button
                            loading={isLoading}
                            type="primary"
                            width="96px"
                            className="button-primary"
                            onClick={() => {
                              form.submit();
                            }}
                            icon={<DownloadOutlined />}
                          >
                            Download
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Spin>
    </div>
  );
};
export default Report;
