import axios from 'axios';
import { message } from 'antd';
import { createAuthCycle } from './authCycle';
import { redirectTo } from './redirect';

require('dotenv').config();

const authorizeURL = process.env.REACT_APP_API_URL;
const authorizeRoleURL = process.env.REACT_APP_API_URL;
const authorizeRoleURLV2 = process.env.REACT_APP_API_URL_V2;

const authorizeRoleApi = axios.create({
  baseURL: authorizeRoleURL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('access-token')}`,
  },
});
const authorizeRoleApiV2 = axios.create({
  baseURL: authorizeRoleURLV2,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('access-token')}`,
  },
});
const authorizeApi = axios.create({
  baseURL: authorizeURL,
  headers: {
    'Content-Type': 'application/json',
  },
});
createAuthCycle(authorizeRoleApi);

//Region: Interceptors (Request)
const setTokenToHeaders = (config) => {
  const token = sessionStorage.getItem('access-token');

  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

authorizeApi.interceptors.request.use(setTokenToHeaders, (err) => {
  return Promise.reject(err);
});

authorizeRoleApi.interceptors.request.use(setTokenToHeaders, (err) => {
  return Promise.reject(err);
});

authorizeRoleApiV2.interceptors.request.use(
  (config) => {
    // Note: to fix the problem on axios.create where the client always uses old token for requesting even after switch role success.
    const token = sessionStorage.getItem('access-token');

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
//End region

//Region: Interceptors (Response)
// TODO token expire must validate from backend front should handle 401 and refresh token only
const onRejected = (error) => {
  if (error.response.status === 401) {
    sessionStorage.clear();
    let secondsToGo = 1;
    message.error('Session Expired. Redirecting you to login page...');
    const timer = setInterval(() => {
      secondsToGo -= 1;
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      redirectTo(`/login`);
    }, secondsToGo * 1000);
  }
  return Promise.reject(error);
};

const onDownload = (response) => {
  try {
    var a = document.createElement('a');
    var blob = new Blob([response.data]);
    var fileName = response.headers['content-disposition']
      .toString()
      .replace(/"/g, '')
      .split('=');
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName[1];
    document.body.appendChild(a); // Append the link to the body before clicking
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(a.href);
    }, 0);
  } catch (ex) {
    message.error(`Error: ${ex.message}`);
  }
};

authorizeApi.interceptors.response.use(null, onRejected);
authorizeRoleApi.interceptors.response.use(null, onRejected);
//End region

// createAuthCycle(authorizedApi);
export const Authentication = {
  signInWithGoogle: (payload, callback, onRejected) =>
    authorizeApi
      .post('/auth/login-with-google', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  forgotPassword: (payload, callback, onRejected) =>
    authorizeApi
      .post('/auth/forgot-password', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  getNewToken: (refreshToken) =>
    authorizeApi
      .get('/auth/refreshAccessToken', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: refreshToken,
        },
      })
      .then(({ data }) => data),
  getNewTokenOnSwitchRole: (payload, callback, onRejected) =>
    authorizeRoleApi
      .post('/auth/refreshTokenOnSwitchRole', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  resetPassword: (payload, callback, onRejected) =>
    authorizeApi
      .post('/auth/reset-password/', payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('reset-token')}`,
        },
      })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
};

export const AnnounceService = {
  fetchAnnouncementList: (params, callback, onRejected) => {
    authorizeRoleApi
      .post('/announcement/getAnnouncements', params)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  getAnnouncement: (id, callback, onRejected) => {
    authorizeRoleApi
      .get('/announcement/fetch/' + id)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  addAnnouncement: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/announcement/add', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  updateAnnouncement: (payload, callback, onRejected) => {
    authorizeRoleApi
      .patch('/announcement/update', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  deleteAnnouncement: (id, callback, onRejected) => {
    authorizeRoleApi
      .delete('/announcement/delete/' + id)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  notifyAnnouncementToLineGroup: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/announcement/noti', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
};

export const HolidayService = {
  getAll: (callback, onRejected) =>
    authorizeRoleApi
      .get('/holiday/getHoliday')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),

  getYear: (payload, callback, onRejected) =>
    authorizeRoleApi
      .get(`/holiday/fetch/${payload}`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  addHoliday: (payload, callback, onRejected) =>
    authorizeRoleApi
      .post('/holiday/create', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),

  editHoliday: (payload, callback, onRejected) => {
    authorizeRoleApi
      .patch(`/holiday/update`, payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },

  deleteHoliday: (payload, callback, onRejected) => {
    authorizeRoleApi
      .patch(`/holiday/delete`, payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
};

export const ImageService = {
  image: {
    getImage: (image, callback, onRejected) =>
      authorizeRoleApi
        .get(`/image/getImage/` + image, {
          responseType: 'blob',
        })
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
    uploadImage: (image, callback) =>
      authorizeRoleApi
        .post(`/image/upload`, image, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => console.log('RESPONSE', response)),
  },
};

export const ReportService = {
  fetchSummaryTimesheetReportByYear: (payload, onRejected) =>
    authorizeRoleApi
      .post('/report/getSummaryTimesheetReportByYear', payload, {
        responseType: 'blob',
      })
      .then((response) => onDownload(response))
      .catch(({ response }) => onRejected(response)),

  fetchSummaryOTTimesheetReportByMonth: (payload, onRejected) =>
    authorizeRoleApi
      .post('/report/getSummaryOTTimesheetReportByMonth', payload, {
        responseType: 'blob',
      })
      .then((response) => onDownload(response))
      .catch(({ response }) => onRejected(response)),

  fetchSummaryTimesheetReportByProject: (payload, onRejected) =>
    authorizeRoleApi
      .post('/report/getSummaryTimesheetReportByProject', payload, {
        responseType: 'blob',
      })
      .then((response) => onDownload(response))
      .catch(({ response }) => onRejected(response)),

  fetchTimesheetReportByMonth: (payload, onRejected) =>
    authorizeRoleApi
      .post('/report/getTimesheetReportByMonth', payload, {
        responseType: 'blob',
      })
      .then((response) => onDownload(response))
      .catch(({ response }) => onRejected(response)),

  fetchMedicalClaimReport: (params, onRejected) =>
    authorizeRoleApiV2
      .get('/report/medical-claim', {
        params,
        headers: {
          'Content-Type': 'blob',
        },
        responseType: 'arraybuffer',
      })
      .then((response) => onDownload(response))
      .catch(({ response }) => onRejected(response)),

  fetchUserInfoReport: (onRejected) =>
    authorizeRoleApi
      .get('/report/getUserInfoReport', {
        responseType: 'blob',
      })
      .then((response) => onDownload(response))
      .catch(({ response }) => onRejected(response)),
  fetchUserProjectByMonth: (payload, callback, onRejected) =>
    authorizeRoleApi
      .post('/report/getUserProjectByMonth', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
};

export const LeaveService = {
  getLeave: (payload, callback, onRejected) =>
    authorizeRoleApi
      .post('/leave/getLeave', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),

  getLeaveByYear: (payload, callback, onRejected) =>
    authorizeRoleApi
      .post('/leave/getLeaveByYear', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),

  getLeaveByLeaveId: (payload, callback, onRejected) =>
    authorizeRoleApi
      .get(`leave/fetchByLeaveId/${payload}`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),

  getRemainByUserId: (params, callback, onRejected) =>
    authorizeRoleApi
      .get(`leave/remain/fetch`, { params })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),

  addLeave: (payload, callback, onRejected) =>
    authorizeRoleApi
      .post('/leave/create', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),

  editLeave: (payload, callback, onRejected) => {
    authorizeRoleApi
      .patch(`/leave/update`, payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
};

export const MasterDataService = {
  fetchInstitutionList: async (params, callback, onRejected) => {
    await authorizeRoleApi
      .get('/master/institution/getAllInstitution', {})
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchEducationMajorList: async (params, callback, onRejected) => {
    await authorizeRoleApi
      .get('/master/educationMajor/getAllEducationMajor', {})
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchDepartmentList: async (params, callback, onRejected) => {
    await authorizeRoleApi
      .get('/master/department/getDepartmentList', {})
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchPositionList: async (params, callback, onRejected) => {
    await authorizeRoleApi
      .get('/master/position/getPositionList', { params })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchLevelList: async (params, callback, onRejected) => {
    await authorizeRoleApi
      .get('/master/user/getAllLevel')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchSkillList: async (params, callback, onRejected) => {
    await authorizeRoleApi
      .get('/master/skill/getComputerSkillType')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchRoleList: async (params, callback, onRejected) => {
    await authorizeRoleApi
      .get('/master/user/getAllRole')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchComputerSkillList: async (params, callback, onRejected) => {
    await authorizeRoleApi
      .get('/master/skill/getComputerSkillType')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchTaskList: async (params, callback, onRejected) => {
    await authorizeRoleApi
      .get('/master/task/getTaskList')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchSupervisorList: async (params, callback, onRejected) => {
    await authorizeRoleApi
      .get('/master/user/getAllSupervisor')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchSectionList: async (params, callback, onRejected) => {
    await authorizeRoleApi
      .get('/master/section/getAllSection')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchProjectTypeList: async (params, callback, onRejected) => {
    await authorizeRoleApi
      .get('/master/project-type/get-all-project-type')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  updateInstitution: (payload, callback, onRejected) =>
    authorizeRoleApi
      .patch('/master/institution/update', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  updateEducationMajor: (payload, callback, onRejected) =>
    authorizeRoleApi
      .patch('/master/educationMajor/update', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  updateDepartment: (payload, callback, onRejected) =>
    authorizeRoleApi
      .patch('/master/department/update', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  updateSection: (payload, callback, onRejected) =>
    authorizeRoleApi
      .patch('/master/section/update', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  updatePosition: (payload, callback, onRejected) =>
    authorizeRoleApi
      .patch('/master/position/update', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  updateLevel: (payload, callback, onRejected) =>
    authorizeRoleApi
      .patch('/master/level/update', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  updateSkill: (payload, callback, onRejected) =>
    authorizeRoleApi
      .patch('/master/skill/update', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  updateTask: (payload, callback, onRejected) =>
    authorizeRoleApi
      .patch('/master/task/update', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  updateProjectType: (payload, callback, onRejected) =>
    authorizeRoleApi
      .patch('/master/project-type/update', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  addInstitution: (payload, callback, onRejected) =>
    authorizeRoleApi
      .post('/master/institution/add', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  addEducationMajor: (payload, callback, onRejected) =>
    authorizeRoleApi
      .post('/master/educationMajor/add', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  addDepartment: (payload, callback, onRejected) =>
    authorizeRoleApi
      .post('/master/department/add', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  addSection: (payload, callback, onRejected) =>
    authorizeRoleApi
      .post('/master/section/add', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  addPosition: (payload, callback, onRejected) =>
    authorizeRoleApi
      .post('/master/position/add', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  addLevel: (payload, callback, onRejected) =>
    authorizeRoleApi
      .post('/master/level/add', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  addSkill: (payload, callback, onRejected) =>
    authorizeRoleApi
      .post('/master/skill/add', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  addTask: (payload, callback, onRejected) =>
    authorizeRoleApi
      .post('/master/task/add', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  addProjectType: (payload, callback, onRejected) =>
    authorizeRoleApi
      .post('/master/project-type/add', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  deleteInstitution: (id, callback, onRejected) =>
    authorizeRoleApi
      .delete('/master/institution/delete/' + id)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  deleteEducationMajor: (id, callback, onRejected) =>
    authorizeRoleApi
      .delete('/master/educationMajor/delete/' + id)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  deleteDepartment: (id, callback, onRejected) =>
    authorizeRoleApi
      .delete('/master/department/delete/' + id)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  deleteSection: (id, callback, onRejected) =>
    authorizeRoleApi
      .delete('/master/section/delete/' + id)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  deletePosition: (id, callback, onRejected) =>
    authorizeRoleApi
      .delete('/master/position/delete/' + id)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  deleteLevel: (id, callback, onRejected) =>
    authorizeRoleApi
      .delete('/master/level/delete/' + id)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  deleteSkill: (id, callback, onRejected) =>
    authorizeRoleApi
      .delete('/master/skill/delete/' + id)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  deleteTask: (id, callback, onRejected) =>
    authorizeRoleApi
      .delete('/master/task/delete/' + id)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  deleteProjectType: (id, callback, onRejected) =>
    authorizeRoleApi
      .delete('/master/project-type/delete/' + id)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
};

export const MedicalClaimService = {
  getAllMedicalClaimHistory: (params, callback, onRejected) => {
    authorizeRoleApiV2
      .get('/medical-claim/list', { params })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  getMedicalClaimHistoryByUserId: (params, callback, onRejected) => {
    authorizeRoleApiV2
      .get('/medical-claim/list', { params })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  getMedicalClaimType: (callback, onRejected) => {
    authorizeRoleApiV2
      .get('/medical-claim-types')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  getMedicalClaimAmountRemainByYear: (params, callback, onRejected) => {
    authorizeRoleApiV2
      .get('/all-medical-claim-amount-remain', { params })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  getMedicalClaimAmountRemain: (params, callback, onRejected) => {
    authorizeRoleApiV2
      .get('/medical-claim-amount-remain', { params })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  /*getMedicalClaimReport: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/medical-claim/export-medical-treatment-report', payload, {
        responseType: 'blob',
      })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },*/
  addMedicalClaimRequest: (payload, callback, onRejected) => {
    authorizeRoleApiV2
      .post('/medical-claim', payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  updateMedicalClaim: (
    medicalClaimRequestId,
    payload,
    callback,
    onRejected,
  ) => {
    authorizeRoleApiV2
      .patch(`/medical-claim/${medicalClaimRequestId}/status`, payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  updateMedicalClaimByUser: (
    medicalClaimRequestId,
    payload,
    callback,
    onRejected,
  ) => {
    authorizeRoleApiV2
      .patch(`/medical-claim/${medicalClaimRequestId}/status`, payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  uploadMedicalClaim: (payload, callback, onRejected) => {
    authorizeRoleApiV2
      .post('/medical-claim/upload', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
};

export const ProfileService = {
  /*profilePicture: {
    getImage: (image, callback) =>
      profileApi
        .get(`/image/get-image/` + image, {
          responseType: 'blob',
        })
        .then(({ data }) => callback({ data }))
        .catch((response) => console.log('RESPONSE', response)),
    editProfileImage: (payload, callback) =>
      profileApi
        .post(`/profiles/profilePicture/update`, payload)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => console.log('RESPONSE', response)),
  },*/
  personalDetail: {
    createPersonalDetail: (payload, callback, onRejected) => {
      authorizeRoleApi
        .post(`/profiles/generalInfo/add/`, payload)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => console.log('RESPONSE', response));
    },
    getPersonalDetail: (emp_index, callback, onRejected) =>
      authorizeRoleApi
        .get(`/user/get-user/${emp_index}`, emp_index)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
    editPersonalDetail: (payload, callback, onRejected) =>
      authorizeRoleApi
        .patch(`/user/update-user/`, payload)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
    changePassword: (payload, callback, onRejected) =>
      authorizeRoleApi
        .patch(`/user/change-password`, payload)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
  },
  workExperience: {
    getWorkExperience: (emp_index, callback, onRejected) =>
      authorizeRoleApi
        .get(`/profiles/workExperiences/fetch/${emp_index}`, emp_index)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
    addWorkExperience: (payload, callback, onRejected) =>
      authorizeRoleApi
        .post(`/profiles/workExperiences/add`, payload)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
    editWorkExperience: (payload, callback, onRejected) =>
      authorizeRoleApi
        .patch(`/profiles/workExperiences/update`, payload)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
    deleteWorkExperience: (id, callback, onRejected) =>
      authorizeRoleApi
        .delete('/profiles/workExperiences/delete/' + id)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
  },
  education: {
    getEducation: (emp_index, callback, onRejected) =>
      authorizeRoleApi
        .get(`/profiles/education/fetch/${emp_index}`, emp_index)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
    addEducation: (payload, callback, onRejected) =>
      authorizeRoleApi
        .post(`/profiles/education/add`, payload)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
    editEducation: (payload, callback, onRejected) =>
      authorizeRoleApi
        .patch(`/profiles/education/update`, payload)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
    deleteEducation: (id, callback, onRejected) =>
      authorizeRoleApi
        .delete('/profiles/education/delete/' + id)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
  },
  trainingCourses: {
    getTrainingCourses: (emp_index, callback, onRejected) =>
      authorizeRoleApi
        .get(`/profiles/trainingCourses/fetch/${emp_index}`, emp_index)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
    addTrainingCourse: (payload, callback, onRejected) =>
      authorizeRoleApi
        .post(`/profiles/trainingCourses/add`, payload)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
    editTrainingCourse: (payload, callback, onRejected) =>
      authorizeRoleApi
        .patch(`/profiles/trainingCourses/update`, payload)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
    deleteTrainingCourse: (id, callback, onRejected) =>
      authorizeRoleApi
        .delete('/profiles/trainingCourses/delete/' + id)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
  },
  address: {
    getAddress: (emp_index, callback, onRejected) =>
      authorizeRoleApi
        .get(`/profiles/address/fetch/${emp_index}`, emp_index)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
    editAddress: (payload, callback) =>
      authorizeRoleApi
        .patch(`/profiles/address/update`, payload)
        .then(({ data }) => callback({ data })),
  },
  certificate: {
    getCertificate: (emp_index, callback, onRejected) =>
      authorizeRoleApi
        .get(`/profiles/certificates/fetch/${emp_index}`, emp_index)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
    addCertificate: (payload, callback, onRejected) =>
      authorizeRoleApi
        .post(`/profiles/certificates/add`, payload)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
    editCertificate: (payload, callback, onRejected) =>
      authorizeRoleApi
        .patch(`/profiles/certificates/update`, payload)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
    deleteCertificate: (id, callback, onRejected) =>
      authorizeRoleApi
        .delete('/profiles/certificates/delete/' + id)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
  },
  computerSkills: {
    getComputerSkills: (emp_index, callback, onRejected) =>
      authorizeRoleApi
        .get(`/profiles/computerSkills/fetch/${emp_index}`, emp_index)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
    addComputerSkills: (payload, callback, onRejected) =>
      authorizeRoleApi
        .post(`/profiles/computerSkills/add`, payload)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
    editComputerSkills: (payload, callback, onRejected) =>
      authorizeRoleApi
        .patch(`/profiles/computerSkills/update`, payload)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
    deleteComputerSkills: (id, callback, onRejected) =>
      authorizeRoleApi
        .delete('/profiles/computerSkills/delete/' + id)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
  },
};

export const ProjectService = {
  getAllProjectType: (params, callback, onRejected) => {
    authorizeRoleApiV2
      .get('/project-type', { params })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchProject: (id, callback, onRejected) =>
    authorizeRoleApi
      .get(`/project/get-project/${id}`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),

  fetchProjectList: (params, callback, onRejected) =>
    authorizeRoleApi
      .get('/project/get-all-project', { params })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  fetchProjectListByUser: (callback, onRejected) =>
    authorizeRoleApi
      .get(`/project/get-all-project-of-user`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  fetchProjectListByUserId: (payload, callback, onRejected) =>
    authorizeRoleApi
      .post(`/project/get-all-project-of-user-by-userId`, payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  fetchActiveProjectListByUser: (callback, onRejected) =>
    authorizeRoleApi
      .get(`/project/get-all-active-project-of-user`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  fetchActiveProjectListByUserNewApi: (params, callback, onRejected) =>
    authorizeRoleApiV2
      .get(`/projects`, { params })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  fetchProjectListByMonth: (payload, callback, onRejected) =>
    authorizeRoleApi
      .post(`/project/get-all-project-of-user-by-month`, payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  fetchProjectListByMonthNewApi: (params, callback, onRejected) =>
    authorizeRoleApiV2
      .get(`/projects/by-month`, { params })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  fetchProjectOtListByUser: (payload, callback, onRejected) =>
    authorizeRoleApi
      .post(`/project/get-all-project-ot-of-user`, payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  fetchProjectOtListByUserNewApi: (params, callback, onRejected) =>
    authorizeRoleApiV2
      .get(`/projects/over-time`, { params })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  addProject: (payload, callback, onRejected) =>
    authorizeRoleApi
      .post('/project/add-project', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  editProject: (payload, callback, onRejected) =>
    authorizeRoleApi
      .patch(`/project/update-project`, payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  updateProjectColor: (payload, callback, onRejected) =>
    authorizeRoleApi
      .patch(`/project/update-user-project-color-code`, payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  deleteProject: (id, callback, onRejected) =>
    authorizeRoleApi
      .delete(`/project/delete-project/${id}`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  progressProject: (callback, onRejected) =>
    authorizeRoleApi
      .get(`master/project/getProjectProgressList`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
};

export const ReferenceLetterService = {
  addReferenceLetterRequest: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/payslip/add', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  updateReferenceLetter: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/payslip/update-request-status-by-admin', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  updateReferenceLetterByUser: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/payslip/update-request-status-by-user', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  getAllReferenceLetterHistory: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/payslip/get-all-payslip-history', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  getReferenceLetterType: (callback, onRejected) => {
    authorizeRoleApi
      .get('/payslip/get-payslip-type')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  getReferenceLetterHistory: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/payslip/get-payslip-history-by-userid', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
};

export const RoleService = {
  getAllRole: (callback, onRejected) => {
    authorizeRoleApi
      .get('/role/getAllRole')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  getAllRolePermission: (callback, onRejected) => {
    authorizeRoleApi
      .get('/role/getAllRolePermission')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  getRolePermissionById: (roleId, callback, onRejected) => {
    authorizeRoleApi
      .get(`/role/getRolePermission/${roleId}`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  addRole: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/role/addRole', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  updateRole: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/role/updateRole', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  deleteRole: (roleId, callback, onRejected) => {
    authorizeRoleApi
      .delete(`/role/deleteRole/${roleId}`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
};

export const SalaryService = {
  getSalaryItem: (callback, onRejected) => {
    authorizeRoleApi
      .get('/salary/salary-item')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  getSalaryFilterOption: (callback, onRejected) => {
    authorizeRoleApi
      .get('/salary/salary-filter-option')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  getSalaryPDFFile: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/salary/salary-pdf', payload, { responseType: 'blob' })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  uploadSalaryExcel: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/salary/upload-excel', payload, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  getYearFilterOptions: (params, callback, onRejected) => {
    authorizeRoleApiV2
      .get('/salary/year-filter-options', { params })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  getMonthFilterOptions: (params, callback, onRejected) => {
    authorizeRoleApiV2
      .get('/salary/month-filter-options', { params })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  getTaxCertificateFilterOption: (params, callback, onRejected) => {
    authorizeRoleApiV2
      .get('/salary/tax-certificate-filter-options', { params })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  getTaxCertificatePDFFile: (params, callback, onRejected) => {
    authorizeRoleApiV2
      .get('/salary/tax-certificate', { params, responseType: 'arraybuffer' })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  getSalariesPDFFile: (params, callback, onRejected) => {
    authorizeRoleApiV2
      .get('/salary/pdf', { params, responseType: 'blob' })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  /*addExcelRecordOld: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/salary/add-excel-record', payload, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },*/
  addExcelRecord: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/salary/upload-salary-record', payload, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  updateSalaryItem: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/salary/update-salary-item', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  updateSalaryItemClass: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/salary/update-salary-item-classification', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
};

export const TimesheetService = {
  getTimeInTimeOutValue: (callback, onRejected) => {
    authorizeRoleApi
      .get('/master/configs/get-time-in-time-out')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  getConfigs: (callback, onRejected) => {
    authorizeRoleApiV2
      .get('/system-setting')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchTimesheetList: (payload, callback, onRejected) =>
    authorizeRoleApi
      .post('/timesheet/getAllTimesheet', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  fetchTimesheetListNewApi: (params, callback, onRejected) =>
    authorizeRoleApiV2
      .get('/timesheet/all', { params })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  /*getTimesheetByDate: (payload, callback, onRejected) =>
    timesheetApi
      .post('/timesheet/getTimesheetByDate', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),*/
  fetchTimesheetDetail: (payload, callback, onRejected) =>
    authorizeRoleApi
      .post('/timesheet/getTimesheetDetailByMonth', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  fetchTimesheetDetailNewApi: (params, callback, onRejected) =>
    authorizeRoleApiV2
      .get('/timesheet', { params })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  addTimesheet: (payload, callback, onRejected) =>
    authorizeRoleApi
      .post('/timesheet/add', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  addTimesheetNewApi: (payload, callback, onRejected) =>
    authorizeRoleApiV2
      .post('/timesheet', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  addOtNewApi: (payload, callback, onRejected) =>
    authorizeRoleApiV2
      .post('/timesheet/over-time', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  updateTimesheet: (payload, callback, onRejected) =>
    authorizeRoleApi
      .patch('/timesheet/update', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  updateTimesheetNewApi: (payload, callback, onRejected) =>
    authorizeRoleApiV2
      .patch('/timesheet', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  updateOverTimeNewApi: (payload, callback, onRejected) =>
    authorizeRoleApiV2
      .patch('/timesheet/over-time', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  deleteTimesheetNewApi: (id, callback, onRejected) =>
    authorizeRoleApiV2
      .delete('/timesheet/' + id)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  deleteTimesheet: (id, callback, onRejected) =>
    authorizeRoleApi
      .delete('/timesheet/delete/' + id)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  addTimesheetHour: (payload, callback, onRejected) =>
    authorizeRoleApi
      .post('/timesheet/add-hour', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  addTimesheetHourNewApi: (payload, callback, onRejected) =>
    authorizeRoleApiV2
      .post('/timesheet/hour', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  fetchTaskList: (callback, onRejected) => {
    authorizeRoleApi
      .get('/timesheet/getTaskList')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchTaskListNewApi: (callback, onRejected) => {
    authorizeRoleApiV2
      .get('/tasks')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
};

export const UserService = {
  fetchUserAll: (payload, callback, onRejected) =>
    authorizeRoleApi
      // .get('/user/get-all-user', { params })
      .post('/user/get-all-user', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  /*fetchAllUserInfo: (callback, onRejected) =>
    userApi
      .get('/user/get-all-user-info')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),*/
  getUserOptions: (params, callback, onRejected) =>
    authorizeRoleApiV2
      .get('/user/filter-options', { params })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  addUser: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/user/add-user', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  getActiveUserCount: (payload, callback, onRejected) => {
    authorizeRoleApi
      .get('/user/getActivedUserCount')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
};

/*export const CustomerService = {
  fetchCustomerList: (payload, callback, onRejected) => {
    customerApi
      .post('/customers/fetchCustomerList/', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchCustomer: (payload, callback, onRejected) => {
    customerApi
      .get(`/customers/fetchCustomer/${payload}`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  addCustomer: (payload, callback, onRejected) => {
    customerApi
      .post('/customers/addCustomer', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  deleteCustomer: (payload, callback, onRejected) => {
    customerApi
      .delete(`/customers/deleteCustomer/${payload}`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  editCustomer: (index, payload, callback, onRejected) => {
    customerApi
      .patch(`/customers/editCustomer/${index}`, payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchContactPerson: (payload, callback, onRejected) => {
    customerApi
      .get(`/customers/fetchContactPerson/${payload}`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchContactPersonList: (payload, callback, onRejected) => {
    customerApi
      .post('/customers/fetchContactPersonList', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  addContactPerson: (payload, callback, onRejected) => {
    customerApi
      .post('/customers/addContactPerson/', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  deleteContactPerson: (payload, callback, onRejected) => {
    customerApi
      .delete(`/customers/deleteContactPerson/${payload}`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  editContactPerson: (index, payload, callback, onRejected) => {
    customerApi
      .patch(`/customers/editContactPerson/${index}`, payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  signInWithEmail: (payload, callback, onRejected) =>
    authorizeApi
      .post('/auth/login', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  signInWithGoogle: () =>
    (window.location = `http://localhost:3001/api/auth/login/google`),
  forgotPassword: (payload, callback, onRejected) =>
    authorizeApi
      .post('/auth/forgetPassword', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  getNewToken: (refreshToken) =>
    authorizeApi
      .get('/auth/refreshAccessToken', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: refreshToken,
        },
      })
      .then(({ data }) => data),
  resetPassword: (payload, callback, onRejected) =>
    authorizeApi
      .post('/resetPassword/', payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('reset-token')}`,
        },
      })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
};*/

/*export const QuotaionService = {
  fetchQuatationList: (payload, callback, onRejected) =>
    quotationApi
      .post('/quotation/fetchQuotationList', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
};*/

/*export const ItemService = {
  addItems: (payload, callback, onRejected) => {
    itemApi
      .post('/items/addItems/', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchItemsList: (payload, callback, onRejected) => {
    itemApi
      .post('/items/fetchItemsList/', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  deleteItems: (payload, callback, onRejected) => {
    itemApi
      .delete(`/items/deleteItems/${payload}/`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchItem: (payload, callback, onRejected) => {
    itemApi
      .get(`/items/fetchItem/${payload}/`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  editItems: (index, payload, callback, onRejected) => {
    itemApi
      .patch(`/items/editItems/${index}/`, payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
};*/

export const ClaimService = {
  fetchClaimRequestList: (params, callback, onRejected) =>
    authorizeRoleApiV2
      .get('/claim/requests', { params })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  fetchClaimTypeList: (params, callback, onRejected) =>
    authorizeRoleApiV2
      .get('/claim-type', { params })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  createClaimRequest: (payload, callback, onRejected) =>
    authorizeRoleApiV2
      .post('/claim/request', payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  updateClaimRequest: (payload, callback, onRejected) =>
    authorizeRoleApiV2
      .patch('/claim/request', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  fetchClaimTypeRule: (param, callback, onRejected) =>
    authorizeRoleApiV2
      .get(`/claim-type/${param}/rule`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  fetchClaimTypeRules: (callback, onRejected) =>
    authorizeRoleApiV2
      .get('/claim-type/rules')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  validateCreateClaimRequest: (claimTypeId, payload, callback, onRejected) =>
    authorizeRoleApiV2
      .post(`/claim-type/${claimTypeId}/validate-creation`, payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  fetchLatestClaimRequestStatus: (params, callback, onRejected) =>
    authorizeRoleApiV2
      .get(`/claim/request/${params}/latest-status`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  fetchClaimRequestInvoice: (params, callback, onRejected) =>
    authorizeRoleApiV2
      .get(`/claim/request/${params}/invoice`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  fetchClaimRequestDocuments: (params, callback, onRejected) =>
    authorizeRoleApiV2
      .get(`/claim/request/${params}/related-documents`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  createClaimRequestStatus: (payload, callback, onRejected) =>
    authorizeRoleApiV2
      .post(`/claim/request/status`, payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
};

export const UserInfoService = {
  fetchUserInfoList: (payload, callback, onRejected) => {
    const params = new URLSearchParams();

    // Manually add each userId as a separate query parameter
    if (payload.userId && Array.isArray(payload.userId)) {
      payload.userId.forEach((id) => params.append('userIds', id));
    }

    if (payload.empCode && Array.isArray(payload.empCode)) {
      payload.empCode.forEach((id) => params.append('empCodes', id));
    }

    // Add other payload fields if needed
    Object.keys(payload).forEach((key) => {
      if (key !== 'userId' && key !== 'empCode') {
        params.append(key, payload[key]);
      }
    });

    authorizeRoleApiV2
      .get(`/user-info/?${params.toString()}`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchUserInfoUnique: (payload, callback, onRejected) => {
    authorizeRoleApiV2
      .get(`/user-info/${payload}`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
};

export const GeneralInfoService = {
  fetchGeneralInfoList: (payload, callback, onRejected) => {
    const params = new URLSearchParams();

    if (payload.empCode && Array.isArray(payload.empCode)) {
      payload.empCode.forEach((id) => params.append('empCodes', id));
    }

    authorizeRoleApiV2
      .get(`/general-info/?${params.toString()}`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
};

export const DepartmentService = {
  fetchDepartmentById: (params, callback, onRejected) =>
    authorizeRoleApiV2
      .get(`/department/${params}`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
};

export const PositionService = {
  fetchPositionById: (params, callback, onRejected) =>
    authorizeRoleApiV2
      .get(`/position/${params}`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
};

export const AwsService = {
  getFile: (query, callback, onRejected) => {
    authorizeRoleApiV2
      .get(`/aws/?filePath=${query}`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  getFiles: (payload, callback, onRejected) => {
    authorizeRoleApiV2
      .post('/aws/download-all', payload, {
        responseType: 'blob',
      })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
};

export const PromotionHistoryService = {
  fetchPromotionHistory: (callback, onRejected) => {
    authorizeRoleApiV2
      .get(`/promotion-history`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  addPromotionHistory: (payload, callback, onRejected) => {
    authorizeRoleApiV2
      .post(`/promotion-history`, payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
};
