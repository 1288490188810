import React, { useContext, useEffect, useState } from 'react';
import { Layout, message } from 'antd';

import { ROLE_ID } from '../utils/constants';
import { AppContext } from '../components/context/AppContext';
import { Text } from '../components';
import Tabs from '../components/tabs';
import BenefitClaim from './employee/benefitClaim';
import BenefitClaimHr from './hr/BenefitClaimHr';
import MedicalClaimUpload from './hr/medicalClaimUpload';
import MedicalClaim from './medicalClaim';
import { useLocation } from 'react-router-dom';
import { FeatureFlags } from '../components/context/FeatureFlagContext';

const { Content } = Layout;

const Claim = () => {
  const { userState } = useContext(AppContext);
  const { features } = useContext(FeatureFlags);
  const [tabKey, setTabKey] = useState('1');
  const location = useLocation();

  useEffect(() => {
    setTabKey(location?.state?.tabKey);
  }, []);

  useEffect(() => {
    if (!features?.medicalClaimView && !features?.benefitClaimView) {
      message.error('You do not have permission to view this screen.', 1);
    }
  }, [features]);

  const items = [
    features?.medicalClaimView && {
      key: '1',
      label: <Text sub3>Medical Claim</Text>,
      children: <MedicalClaim />,
    },
    features?.benefitClaimView && {
      key: '2',
      label: <Text sub3>Benefit Claim</Text>,
      children:
        userState?.currentRoleId === ROLE_ID.User ? (
          <BenefitClaim />
        ) : (
          <BenefitClaimHr />
        ),
    },
  ].filter(Boolean);

  useEffect(() => {
    if (!items.find((item) => item.key === tabKey)) {
      setTabKey(items[0]?.key || '');
    }
  }, [items]);

  return (
    <div style={{ width: 'auto' }}>
      <Layout style={{ minHeight: 'calc(100vh + 64px)' }}>
        {(features?.medicalClaimView || features?.benefitClaimView) && (
          <Content
            style={{
              margin: '32px 20px 0 20px',
              overflow: 'initial',
            }}
          >
            <Tabs
              activeKey={tabKey}
              items={items}
              onTabClick={(key) => setTabKey(key)}
            />
          </Content>
        )}
      </Layout>
    </div>
  );
};

export default Claim;
