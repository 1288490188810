//Modified flag

export const FlagEnum = {
  //Announcement
  announceViewPage: 'ANNOUNCEMENT_VIEW', //Primary Actions
  announceCreate: 'ANNOUNCEMENT_CREATE',
  announceEdit: 'ANNOUNCEMENT_EDIT',
  announceDelete: 'ANNOUNCEMENT_DELETE',
  announcePublish: 'ANNOUNCEMENT_PUBLISH',

  //Holiday
  holidayViewPage: 'HOLIDAY_VIEW', //Primary Actions
  holidayCreate: 'HOLIDAY_CREATE',
  holidayDelete: 'HOLIDAY_DELETE',

  //Leave
  leaveViewPage: 'LEAVE_VIEW', //Primary Actions

  //Medical Claim
  medicalClaimView: 'MEDICALCLAIM_TAB_VIEW',
  medicalClaimApproveRejectRequest: 'MEDICALCLAIM_APPROVE_REJECT',
  medicalClaimExportReport: 'MEDICALCLAIM_EXPORT',
  medicalClaimUploadPage: 'MEDICALCLAIM_UPLOAD',

  //Payslip
  payslipViewPage: 'PAYSLIP_VIEW', //Primary Actions
  payslipUpload: 'PAYSLIP_UPLOAD',
  payslipEditSettings: 'PAYSLIP_EDIT',
  payslipTabView: 'PAYSLIP_TAB_VIEW',
  taxCertificateTabView: 'TAX_CERTIFICATE_TAB_VIEW',

  //Project
  projectViewPage: 'PROJECT_VIEW', //Primary Actions
  projectCreate: 'PROJECT_CREATE',
  projectEdit: 'PROJECT_EDIT',
  projectDelete: 'PROJECT_DELETE',
  projectManageOTDate: 'PROJECT_MANAGE_OT', // Add edit delete OT date
  projectManageMember: 'PROJECT_MANAGE_MEMBER', // Add edit delete view timesheet project member

  //Report
  reportViewPage: 'REPORT_VIEW', //Primary Actions

  //Reference Letter
  referenceLetterViewPage: 'REFERENCELETTER_VIEW', //Primary Actions
  referenceLetterApproveOrReject: 'REFERENCELETTER_APPROVE_REJECT',

  //Role & Permission
  roleViewPage: 'ROLE&PERMISSION_VIEW', //Primary Actions
  roleCreate: 'ROLE&PERMISSION_CREATE',
  roleEdit: 'ROLE&PERMISSION_EDIT',
  roleDelete: 'ROLE&PERMISSION_DELETE',

  //Timesheet
  timesheetViewPage: 'TIMESHEET_VIEW', //Primary Actions

  //Settings
  settingsViewPage: 'SETTINGS_VIEW', //Primary Actions
  settingsCreateRecord: 'SETTINGS_CREATE',
  settingsEditRecord: 'SETTINGS_EDIT',
  settingsDeleteRecord: 'SETTINGS_DELETE',

  //User
  userViewPage: 'USER_VIEW', //Primary Actions
  userCreate: 'USER_CREATE',
  userEdit: 'USER_EDIT',
  userExportReport: 'USER_EXPORT',
  userTabView: 'USER_TAB_VIEW',
  promotionTabView: 'PROMOTION_TAB_VIEW',
  promotionUpload: 'PROMOTION_UPLOAD',

  //Claim
  claimViewPage: 'CLAIM_VIEW', //Primary Actions
  claimCreate: 'CLAIM_CREATE',
  claimApproveRejectRequest: 'CLAIM_APPROVE_REJECT',
  benefitClaimView: 'CLAIM_TAB_VIEW',
};
