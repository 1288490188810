import { Button, Col, Form, Row, Spin, message, Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { SalaryService } from '../../services/api';

import { Select, FormInput } from '../../components';
import { Text } from '../../components';

import { DownloadOutlined } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import jwt from 'jsonwebtoken';

const { Content } = Layout;

var token = sessionStorage.getItem('access-token');
var decode1 = jwt.decode(token);
const userId = decode1?.userId;

const SalaryDownloadPage = () => {
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [isPayslipLoading, setIsPayslipLoading] = useState(false);
  const [yearOptions, setYearOptions] = useState();
  const [monthOptions, setMonthOptions] = useState();

  useEffect(() => {
    const params = {
      userId: userId,
    };
    SalaryService.getYearFilterOptions(
      params,
      ({ data }) => {
        setYearOptions(data);
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        if (error) {
          message.error(`Error: ${error.data.message}`);
        }
      },
    );
  }, []);

  const onFinishDownloadSalary = (value) => {
    setIsPayslipLoading(true);
    const index = value.index;
    const params = {
      year: value.year,
      month: value.month,
    };
    SalaryService.getSalariesPDFFile(
      params,
      async ({ data }) => {
        const a = document.createElement('a');
        const blob = new Blob([data], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        a.href = url;
        a.download = `payslip-${value.month.padStart(2, '0')}-${
          value.year
        }.pdf`;
        document.body.appendChild(a);
        a.click();
        setIsPayslipLoading(false);
        setTimeout(function () {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      },
      (response) => {
        setIsPayslipLoading(false);
        if (response) {
          message.error(
            `Failed to get payslip report: ${response.data.message}`,
          );
        }
      },
    );
  };

  const getMonthFilterOption = (userId, year) => {
    form.resetFields(['month']);
    const params = {
      userId: 667,
      year: year,
    };
    SalaryService.getMonthFilterOptions(
      params,
      ({ data }) => {
        setMonthOptions(data);
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        if (error) {
          message.error(`Error: ${error.data.message}`);
        }
      },
    );
  };

  return (
    <div style={{ width: 'auto' }}>
      <Spin spinning={isLoading}>
        <Layout style={{ minHeight: 'calc(100vh - 64px)' }}>
          <Content
            style={{
              margin: '0px 20px 0 20px',
              overflow: 'initial',
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Row gutter={[16, 16]}>
                  <Col flex>
                    <Text h4 className="text-normal">
                      Pay Slip
                    </Text>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Form form={form} onFinish={onFinishDownloadSalary}>
                  <Row className="card-container" gutter={[16, 16]}>
                    <Col span={24}>
                      <FormInput
                        title="Payslip Summary (Month - Year)"
                        component={
                          <Row gutter={[16, 16]}>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                              <Form.Item
                                name="year"
                                extra={
                                  <span
                                    style={{
                                      fontSize: '12px',
                                      color: '#52575C',
                                    }}
                                  ></span>
                                }
                                style={{ margin: 0 }}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please select year',
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Select pay slip summary (year)"
                                  onSelect={(year) =>
                                    getMonthFilterOption(userId, year)
                                  }
                                >
                                  {yearOptions?.map((value) => (
                                    <Select.Option
                                      value={value.option.split(' ')[0]}
                                    >
                                      {value.option}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                              <Form.Item
                                name="month"
                                extra={
                                  <span
                                    style={{
                                      fontSize: '12px',
                                      color: '#52575C',
                                    }}
                                  ></span>
                                }
                                style={{ margin: 0 }}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please Select Month',
                                  },
                                ]}
                              >
                                <Select placeholder="Select pay slip summary (month)">
                                  {monthOptions?.map((value) => (
                                    <Select.Option
                                      value={value.option.split(' ')[0]}
                                    >
                                      {value.option}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                        }
                      />
                    </Col>
                    <Col span={24}>
                      <div>
                        <div>
                          <Text small12 className="text-grey">
                            * To access the supplementary files, please use the
                            following password format:
                          </Text>
                        </div>
                        <div>
                          <Text small12 className="text-secondary-red">
                            Employee ID (5 digits) + National ID (13 digits) +
                            Birth Month (01-12) + Birth Day (01-31)
                          </Text>
                        </div>
                      </div>
                    </Col>
                    <Col span={24}>
                      <Row justify="end">
                        <Col>
                          <Button
                            loading={isPayslipLoading}
                            type="primary"
                            width="96px"
                            className="button-primary"
                            onClick={() => form.submit()}
                            icon={<DownloadOutlined />}
                          >
                            Download
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Spin>
    </div>
  );
};

export default SalaryDownloadPage;
