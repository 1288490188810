import React, { useContext, useEffect, useState } from 'react';
import { Layout, message } from 'antd';

import { Text } from '../../components';
import Tabs from '../../components/tabs';
import { FeatureFlags } from '../../components/context/FeatureFlagContext';
import AllUser from '../admin/user';
import PromoteViewPage from '../hr/promotion';
import { useLocation } from 'react-router-dom';

const { Content } = Layout;

const User = () => {
  const { features } = useContext(FeatureFlags);
  const [tabKey, setTabKey] = useState('1');
  const location = useLocation();

  useEffect(() => {
    setTabKey(location?.state?.tabKey);
  }, []);

  useEffect(() => {
    if (!features?.userTabView && !features?.promotionTabView) {
      message.error('You do not have permission to view this screen.', 1);
    }
  }, [features]);

  const items = [
    features?.userTabView && {
      key: '1',
      label: <Text sub3>User</Text>,
      children: <AllUser />,
    },
    features?.promotionTabView && {
      key: '2',
      label: <Text sub3>Promotion</Text>,
      children: <PromoteViewPage />,
    },
  ].filter(Boolean);

  useEffect(() => {
    if (!items.find((item) => item.key === tabKey)) {
      setTabKey(items[0]?.key || '');
    }
  }, [items]);

  return (
    <div style={{ width: 'auto' }}>
      <Layout style={{ minHeight: 'calc(100vh - 64px)' }}>
        {(features?.userTabView || features?.promotionTabView) && (
          <Content
            style={{
              margin: '32px 20px 0 20px',
              overflow: 'initial',
            }}
          >
            <Tabs
              activeKey={tabKey}
              items={items}
              onTabClick={(key) => setTabKey(key)}
            />
          </Content>
        )}
      </Layout>
    </div>
  );
};
export default User;
