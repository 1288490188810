import {
  Button,
  Col,
  Form,
  Row,
  Spin,
  message,
  Layout,
  AutoComplete,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { SalaryService, UserService } from '../../services/api';

import { Select, FormInput } from '../../components';
import { Text } from '../../components';

import { DownloadOutlined } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';

const { Content } = Layout;

const TaxCertificateAdminPage = () => {
  const [form] = useForm();

  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isPayslipLoading, setIsPayslipLoading] = useState(false);
  const [optionList, setOptionList] = useState(null);
  const [optionsData, setOptionsData] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);

  useEffect(() => {
    getUserOptions();
  }, []);

  const getTaxCertificateFilterOption = (userId) => {
    const params = {
      userId: userId,
    };
    SalaryService.getTaxCertificateFilterOption(
      params,
      ({ data }) => {
        setData(data);
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        if (error) {
          message.error(`Error: ${error.data.message}`);
        }
      },
    );
  };

  const getUserOptions = () => {
    UserService.getUserOptions(
      {
        isActived: 'all',
      },
      ({ data }) => {
        setOptionsData(data);
        setIsLoading(false);
      },
      (error) => {
        if (error) message.error(`Error: ${error.data.message}`);
        setIsLoading(false);
      },
    );
  };

  const onFinishDownloadSalary = (value) => {
    setIsPayslipLoading(true);
    const params = { year: value.index, userId: value.userId };
    SalaryService.getTaxCertificatePDFFile(
      params,
      async ({ data }) => {
        const a = document.createElement('a');
        const blob = new Blob([data], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        a.href = url;
        a.download = `50tavi-${params.year}.pdf`;
        document.body.appendChild(a);
        a.click();
        setIsPayslipLoading(false);
        setTimeout(function () {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      },
      (response) => {
        setIsPayslipLoading(false);
        if (response) {
          message.error(
            `Failed to get payslip report: ${response.data.message}`,
          );
        }
      },
    );
  };

  const selectOptions = (search) => {
    const selectedUser = optionsData.find((option) => option.email === search);
    form.resetFields(['index']);
    setSelectedUserId(selectedUser.userId);
    getTaxCertificateFilterOption(selectedUser.userId);
  };

  const getOptions = (search) => {
    let isSearchThai = search && /[\u0E00-\u0E7F]/g.test(search);
    const emailList = optionsData
      ?.filter(
        (optionData) =>
          (optionData.email &&
            optionData.email.toLowerCase().includes(search.toLowerCase())) ||
          (optionData.firstnameEn &&
            optionData.firstnameEn
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (optionData.lastnameEn &&
            optionData.lastnameEn
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (optionData.nicknameEn &&
            optionData.nicknameEn
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (optionData.empCode &&
            optionData.empCode.toLowerCase().includes(search.toLowerCase())) ||
          (optionData.firstnameTh && optionData.firstnameTh.includes(search)) ||
          (optionData.lastnameTh && optionData.lastnameTh.includes(search)) ||
          (optionData.nicknameTh && optionData.nicknameTh.includes(search)),
      )
      .map((res) => {
        const getHighlightedText = (text) => {
          if (text !== null) {
            let searchCriteria = text;
            let index = searchCriteria
              .toLowerCase()
              .indexOf(search.toLowerCase());

            if (search.length != 0 && index !== -1) {
              let prefix = searchCriteria.substr(0, index);
              let subfix = searchCriteria.substr(index + search.length);
              let matched = searchCriteria.substr(index, search.length);
              return (
                <>
                  {prefix}
                  <span style={{ fontWeight: 'bold', fontColor: '#004368' }}>
                    {matched}
                  </span>
                  {subfix}
                </>
              );
            } else if (
              !isSearchThai &&
              ((res.firstnameEn ? res.firstnameEn : '')
                .toLowerCase()
                .indexOf(search.toLowerCase().trim()) !== -1 ||
                (res.lastnameEn ? res.lastnameEn : '')
                  .toLowerCase()
                  .indexOf(search.toLowerCase().trim()) !== -1 ||
                (res.email ? res.email : '')
                  .toLowerCase()
                  .indexOf(search.toLowerCase().trim()) !== -1 ||
                (res.nicknameEn ? res.nicknameEn : '')
                  .toLowerCase()
                  .indexOf(search.toLowerCase().trim()) !== -1 ||
                (res.empCode ? res.empCode : '')
                  .toLowerCase()
                  .indexOf(search.toLowerCase().trim()) !== -1)
            ) {
              return (
                [
                  res.firstnameEn,
                  res.lastnameEn,
                  res.nicknameEn,
                  res.email,
                  res.empCode,
                ].includes(text) && text
              );
            } else {
              return (
                [
                  res.firstnameTh,
                  res.lastnameTh,
                  res.nicknameTh,
                  res.email,
                  res.empCode,
                ].includes(text) && text
              );
            }
          }
        };

        return {
          value: res.email,
          label: (
            <Row>
              <Col span={15}>
                <Typography.Title
                  style={{
                    fontWeight: 'normal',
                    fontSize: '15px',
                  }}
                  level={5}
                  ellipsis={{
                    rows: 1,
                  }}
                >
                  {getHighlightedText(res.firstnameEn)}
                  {getHighlightedText(res.firstnameTh)}{' '}
                  {getHighlightedText(res.lastnameEn)}
                  {getHighlightedText(res.lastnameTh)}
                </Typography.Title>

                <Typography.Text
                  style={{
                    fontWeight: 'normal',
                    fontSize: '12px',
                  }}
                  ellipsis={{
                    rows: 1,
                  }}
                >
                  {getHighlightedText(res.email)}
                </Typography.Text>
              </Col>
              <Col span={5} offset={3}>
                <Typography.Title
                  style={{
                    fontWeight: 'normal',
                    fontSize: '15px',
                    textAlign: 'left',
                  }}
                  level={5}
                  ellipsis={{
                    rows: 1,
                  }}
                >
                  {getHighlightedText(res.nicknameEn)}
                  {getHighlightedText(res.nicknameTh)}
                </Typography.Title>

                <Typography.Text
                  style={{
                    fontWeight: 'normal',
                    fontSize: '12px',
                  }}
                  ellipsis={{
                    rows: 1,
                  }}
                >
                  {getHighlightedText(res.empCode)}
                </Typography.Text>
              </Col>
            </Row>
          ),
        };
      });
    setOptionList(emailList);
  };

  return (
    <div style={{ width: 'auto' }}>
      <Spin spinning={isLoading}>
        <Layout style={{ minHeight: 'calc(100vh - 176px)' }}>
          <Content
            style={{
              margin: '0px 20px 0 20px',
              overflow: 'initial',
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Row gutter={[16, 16]}>
                  <Col flex>
                    <Text h4 className="text-normal">
                      Tax Certificate
                    </Text>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Form
                  form={form}
                  onFinish={(value) => {
                    value = {
                      ...value,
                      userId: selectedUserId,
                    };
                    onFinishDownloadSalary(value);
                  }}
                >
                  <Row className="card-container" gutter={[16, 16]}>
                    <Col span={24}>
                      <FormInput
                        title="Tax Certificate Summary (Year)"
                        component={
                          <Row gutter={[16, 16]}>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                              <Form.Item
                                id="userId"
                                style={{ width: '100%', margin: 0 }}
                                name="userId"
                                extra={
                                  <span
                                    style={{
                                      fontSize: '12px',
                                      color: '#52575C',
                                    }}
                                  >
                                    Tax Certificate will be updated at the end
                                    of each month.
                                  </span>
                                }
                              >
                                <AutoComplete
                                  options={optionList}
                                  onSelect={selectOptions}
                                  onChange={getOptions}
                                  placeholder="search by Employee No., Full Name, Nickname, or Email"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                              <Form.Item
                                name="index"
                                extra={
                                  <span
                                    style={{
                                      fontSize: '12px',
                                      color: '#52575C',
                                    }}
                                  ></span>
                                }
                                style={{ margin: 0 }}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      'Please Select your Month and Year',
                                  },
                                ]}
                              >
                                <Select placeholder="Select Tax Certificate summary (year(summary month))">
                                  {data?.map((value) => (
                                    <Select.Option
                                      value={value.option.split(' ')[0]}
                                    >
                                      {value.option}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                        }
                      />
                    </Col>

                    <Col span={24}>
                      <div>
                        <div>
                          <Text small12 className="text-grey">
                            * To access the supplementary files, please use the
                            following password format:
                          </Text>
                        </div>
                        <div>
                          <Text small12 className="text-secondary-red">
                            Employee ID (5 digits) + National ID (13 digits) +
                            Birth Month (01-12) + Birth Day (01-31)
                          </Text>
                        </div>
                      </div>
                    </Col>
                    <Col span={24}>
                      <Row justify="end">
                        <Col>
                          <Button
                            loading={isPayslipLoading}
                            type="primary"
                            width="96px"
                            className="button-primary"
                            onClick={() => form.submit()}
                            icon={<DownloadOutlined />}
                          >
                            Download
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Spin>
    </div>
  );
};

export default TaxCertificateAdminPage;
