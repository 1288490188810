import React, { useContext, useEffect } from 'react';
import { Layout, message } from 'antd';

import { Text } from '../../components';
import Tabs from '../../components/tabs';
import TaxCertificateAdminPage from './taxCertificateAdmin';
import PaySlipDownload from '../accountant/paySlipDownload';
import { FeatureFlags } from '../../components/context/FeatureFlagContext';

const { Content } = Layout;

const PaySlipAdmin = () => {
  const { features } = useContext(FeatureFlags);

  useEffect(() => {
    if (!features?.payslipTabView && !features?.taxCertificateTabView) {
      message.error('You do not have permission to view this screen.', 1);
    }
  }, [features]);

  const items = [
    features?.payslipTabView && {
      key: '1',
      label: <Text sub3>Pay Slip</Text>,
      children: <PaySlipDownload />,
    },
    features?.taxCertificateTabView && {
      key: '2',
      label: <Text sub3>Tax Certificate</Text>,
      children: <TaxCertificateAdminPage />,
    },
  ].filter(Boolean);

  return (
    <div style={{ width: 'auto' }}>
      <Layout style={{ minHeight: 'calc(100vh - 64px)' }}>
        {(features?.payslipTabView || features?.taxCertificateTabView) && (
          <Content
            style={{
              margin: '32px 20px 0 20px',
              overflow: 'initial',
            }}
          >
            <Tabs defaultActiveKey="1" items={items} />
          </Content>
        )}
      </Layout>
    </div>
  );
};
export default PaySlipAdmin;
